import { InputBase } from '../../classes/input-base';
import { InputText } from '../../classes/input-text';

export class MailToForm {
	name = new InputText({
		key: 'name',
		label: 'Name',
		required: true,
	});

	email = new InputText({
		key: 'email',
		label: 'Email Address',
		required: true,
	});

	content = new InputBase({
		key: 'content',
		label: 'Content',
		controlType: 'textarea',
		required: true,
	});
}
