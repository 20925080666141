import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { ServerConnectionService } from '../server-connection.service';
import { CoreModule } from '../../core.module';

@Injectable({
	providedIn: CoreModule,
})
export class SupportService {
	private supports = new ReplaySubject<Array<any>>(1);

	private dataStore = { supports: [] };

	constructor(
		private scs: ServerConnectionService,
	) { }

	get supports$() {
		return this.supports.asObservable();
	}

	update() {
		this.scs.http$('GET', '/api/support', null, null).subscribe((data: any) => {
			this.dataStore.supports = data.supports;
			this.supports.next(this.dataStore.supports);
		});
	}
}
