export class InputBase<T> {
	value: T;

	key: string;

	label: string;

	required: boolean;

	disabled: boolean;

	reverseInput: boolean;

	type: string;

	class: string;

	controlType: string;

	minLength = 0;

	maxLength = 0;

	min: number;

	max: number;

	pattern = '';

	buttonSymbol = '';

	helper = '';

	blur = false;

	addonSymbol = '';

	placeholder = '';

	tooltip = '';

	tooltipText = '';

	options: any;

	delete: boolean;

	infoLabel: string;

	constructor(options: {
		value?: T;
		key?: string;
		label?: string;
		required?: boolean;
		disabled?: boolean;
		reverseInput?: boolean;
		type?: string;
		controlType?: string;
		minLength?: number;
		min?: number;
		max?: number;
		maxLength?: number;
		pattern?: string;
		buttonSymbol?: string;
		helper?: string;
		blur?: boolean;
		addonSymbol?: string;
		placeholder?: string;
		tooltip?: string;
		tooltipText?: string;
		delete?: boolean;
		infoLabel?: string;
	} = {}) {
		this.value = options.value;
		this.key = options.key || '';
		this.label = options.label || '';
		this.required = options.required || false;
		this.disabled = options.disabled || false;
		this.delete = options.delete || false;
		this.reverseInput = options.reverseInput || false;
		this.type = options.type || '';
		this.controlType = options.controlType || '';
		this.minLength = options.minLength;
		this.maxLength = options.maxLength;
		this.min = options.min;
		this.max = options.max;
		this.pattern = options.pattern || '';
		this.buttonSymbol = options.buttonSymbol || '';
		this.helper = options.helper || '';
		this.blur = options.blur || false;
		this.addonSymbol = options.addonSymbol || '';
		this.placeholder = options.placeholder || '';
		this.tooltip = options.tooltip || '';
		this.tooltipText = options.tooltipText || '';
		this.infoLabel = options.infoLabel || '';
	}
}
