import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CoreModule } from '../core.module';
import { environment } from '../../../environments/environment';

@Injectable({
	providedIn: CoreModule,
})
export class ServerConnectionService {
	constructor(private httpc: HttpClient) {
	}

	public http$(method, url, query, data) {
		let _url = url;
		// For Local Staging -- Simple way to run app locally with staging server handling the backend.
		if (environment.urlOverride && _url.includes('http') === false) {
			_url = environment.urlOverride + _url;
		}
		if (method === 'GET') {
			if (query) {
				_url = `${_url}?${query}`;
			}
			return this.httpc.get(_url).pipe(
				catchError(this.handleError(_url, [])),
			);
		} if (method === 'POST') {
			if (query) {
				_url = `${_url}?${query}`;
			}
			return this.httpc.post(_url, data).pipe(
				catchError(this.handleError(_url, [])),
			);
		} if (method === 'PUT') {
			if (query) {
				_url = `${_url}?${query}`;
			}
			return this.httpc.put(_url, data).pipe(
				catchError(this.handleError(_url, [])),
			);
		} if (method === 'DELETE') {
			if (query) {
				_url = `${_url}?${query}`;
			}
			return this.httpc.delete(_url).pipe(
				catchError(this.handleError(_url, [])),
			);
		}

		return throwError('Invalid HTTP method');
	}

	private handleError<T>(operation = 'operation', result?: T) {
		return (error: any): Observable<T> => of(result as T);
	}
}
