import { Component, ElementRef, Input, OnInit, OnDestroy } from '@angular/core';
import * as $ from 'jquery';
import { ModalService } from '../../core/services/modal.service';

@Component({
	selector: 'app-modal',
	templateUrl: './modal.component.html',
})
export class ModalComponent implements OnInit, OnDestroy {
	@Input() id: String;

	@Input() maskClose: boolean;

	private element;

	private isOpen;

	constructor(
		private modalService: ModalService,
		private el: ElementRef,
	) {
		this.element = $(el.nativeElement);
	}

	ngOnInit(): void {
		const modal = this;
		if (!this.id) {
			return;
		}
		this.element.appendTo('body');
		if (this.maskClose) {
			this.element.on('click', (e) => {
				const target = $(e.target);
				if (!target.closest('.modal-body').length) {
					modal.close();
				}
			});
		}
		this.modalService.add(this);
	}

	ngOnDestroy(): void {
		if (this.id) {
			this.modalService.remove(this.id.toString());
			this.element.remove();
		}
	}

	close(): void {
		this.element.hide();
		this.isOpen = false;
		$('body').removeClass('modal-open');
	}

	open(): void {
		this.element.show();
		this.isOpen = true;
		$('body').addClass('modal-open');
	}
}
