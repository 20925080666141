import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { InputBase } from '../../classes/input-base';
import { ModalService } from '../../core/services/modal.service';
import { UserService } from '../../core/services/data/user.service';

@Component({
	selector: 'app-form-input',
	templateUrl: 'form-input.component.html',
	styleUrls: ['./form-input.component.scss'],
})

export class FormInputComponent implements OnInit, AfterViewInit {
	@Input() input: InputBase<any> = new InputBase();

	@Input() form: UntypedFormGroup;

	@Input() toggleOn = false;

	@Input() selectorFn = null;

	@Output() submitter = new EventEmitter();

	@Output() inputButton = new EventEmitter();

	@Output() blurHappened = new EventEmitter();

	@Output() changed = new EventEmitter();

	@ViewChild('ngSelect') ngSelect;

	public hour = '4';

	public min = '00';

	public am = 'AM';

	public showTimePicker = false;

	public selDate;

	public showTooltip = false;

	author$;

	author;

	constructor(
		public ms: ModalService,
		private us: UserService,
	) { }

	ngOnInit() {
		if (this.input.controlType === 'time') {
			if (this.input.value) {
				const timePart = this.input.value.split(' ');
				[, this.am] = timePart;
				const hm = timePart[0].split(':');
				[this.hour, this.min] = hm;
			} else {
				this.input.value = '';
			}
		}
		if (this.input.controlType === 'date') {
			if (this.input.value) {
				const date = this.input.value.split('/');
				date[1] = parseInt(date[1], 10).toString();
				date[0] = parseInt(date[0], 10).toString();
				this.selDate = {
					year: date[2],
					month: date[0],
					day: date[1],
				};
			}
		}

		this.author$ = this.us.author$.subscribe((author) => {
			this.author = author;
		});
	}

	ngAfterViewInit(): void {
		this.initSelect();
	}

	getError() {
		const err = this.form.controls[this.input.key].errors;
		if (err) {
			if (typeof err.required !== 'undefined') {
				return 'Required';
			}
			if (typeof err.minlength !== 'undefined') {
				return `Must be at least ${err.minlength.requiredLength} characters`;
			}
			if (typeof err.maxlength !== 'undefined') {
				return `Must be at most ${err.maxlength.requiredLength} characters`;
			}
			if (typeof err.pattern !== 'undefined') {
				if (this.input.key === 'access_code') {
					return 'Letters and Numbers only';
				}
				if (this.input.key.indexOf('email') >= 0) {
					return 'A valid email address is required';
				}
				return 'Invalid Characters';
			}
			return 'Invalid';
		}
		return 'Null field';
	}

	onSelectChange(option: any) {
		if (option.key.includes('-')) {
			option.key = option.key.split('-')[1].trim();
		}
		this.form.controls[this.input.key].patchValue(option.value);

		this.changed.emit({ key: this.input.key, value: option.value });
	}

	deleteInput(value) {
		const id = `${value}Input`;
		this.form.controls[value].patchValue('');
		document.getElementById(id).style.display = 'none';
	}

	setSelectValue(value) {
		if (!this.ngSelect) return;

		if (this.selectorFn) {
			const _item = this.selectorFn(this.ngSelect.itemsList, value);
			if (_item) {
				this.ngSelect.select(_item);
				return;
			}
		}

		const item = this.ngSelect.itemsList.findByLabel(value);
		if (!item) return;

		this.ngSelect.select(item);
	}

	onDateChanged(event) {
		let newDate = '';
		if (typeof event.date.year !== 'undefined') {
			if (event.date.month < 10) {
				newDate += '0';
			}
			newDate += `${event.date.month}/`;
			if (event.date.day < 10) {
				newDate += '0';
			}
			newDate += `${event.date.day}/`;
			newDate += event.date.year;
		}

		this.form.controls[this.input.key].patchValue(newDate);
	}

	blurEvent() {
		if (this.input.blur) {
			this.blurHappened.emit();
		}
	}

	inputButtonClicked() {
		this.inputButton.emit();
	}

	toggleTimePicker(event) {
		event.preventDefault();
		this.showTimePicker = true;
	}

	hideTimePicker() {
		this.showTimePicker = false;
	}

	changeHour(amount) {
		let newHour = parseInt(this.hour, 10);
		newHour += amount;
		if (newHour < 1) {
			newHour = 12;
		}
		if (newHour > 12) {
			newHour = 1;
		}
		this.hour = newHour.toString();
		this.setTime();
	}

	toggled() {
		if (!this.input.disabled) {
			this.toggleOn = !this.toggleOn;
			this.input.value = this.toggleOn;
			this.form.controls[this.input.key].patchValue(this.input.value);
		}
	}

	changeMin(amount) {
		let newMinute = parseInt(this.min, 10);
		newMinute += amount;
		if (newMinute < 0) {
			newMinute = 55;
		}
		if (newMinute > 60) {
			newMinute = 5;
		}
		this.min = newMinute.toString();
		if (this.min.length < 2) {
			this.min = `0${this.min}`;
		}
		this.setTime();
	}

	changeAm() {
		this.am = this.am === 'AM' ? 'PM' : 'AM';
		this.setTime();
	}

	setTime() {
		this.form.controls[this.input.key].patchValue(`${this.hour}:${this.min} ${this.am}`);
	}

	get isValid() {
		return this.form.controls[this.input.key].valid;
	}

	get isUntouched() {
		return this.form.controls[this.input.key].untouched;
	}

	preventOutOfRange(e) {
		if (this.input.min !== undefined && e.target.value < this.input.min) {
			e.target.value = this.input.min;
		}

		if (this.input.max !== undefined && e.target.value > this.input.max) {
			e.target.value = this.input.max;
		}

		this.changed.emit({ key: this.input.key, value: e.target.value });
	}

	initSelect() {
		if (!this.author) return;

		const { key } = this.input;
		let value = '';
		if (this.author.authorProfile?.[key]) value = this.author.authorProfile[key];

		this.setSelectValue(String(value));
	}
}
