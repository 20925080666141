import { Injectable } from '@angular/core';
import { first, of, Subject } from 'rxjs';
import { concatMap, switchMap } from 'rxjs/operators';

import { CoreModule } from '../../core.module';

import { MemberRoleService } from './member-roles.service';
import { ServerConnectionService } from '../server-connection.service';
import { StripeService } from '../stripe.service';
import { UserService } from './user.service';

import { IMemberRole, IUser } from '../../../types/datatypes';
import { IStripeCustomer } from '../../../types/local';
import { MemberRolesAPIResponse, SettingAPIResponse } from '../../../types/responses';

interface customWindow extends Window {
	Rewardful?: any;
	dataLayer?: any;
	development?: any;
}
declare const window: customWindow;
@Injectable({
	providedIn: CoreModule,
})
export class MembershipUpgradeService {
	public author: IUser;

	public customer: IStripeCustomer;

	public plans: IMemberRole[] = [];

	public selectedPlanName = null;

	public plan: IMemberRole = null;

	public goldMemberDiscount;

	public platinumMemberDiscount;

	private _loading = new Subject<boolean>();

	constructor(
		private memberRoleService: MemberRoleService,

		private serverConnectionService: ServerConnectionService,

		private stripeService: StripeService,

		private userService: UserService,
	) {
		this._loading.next(true);

		this.serverConnectionService.http$('GET', '/api/settings/gold_member_discount', null, null)
			.subscribe((goldMemberSetting: SettingAPIResponse) => {
				if (goldMemberSetting.success) {
					this.goldMemberDiscount = goldMemberSetting.setting.value;
				}
			});

		this.serverConnectionService.http$('GET', '/api/settings/platinum_member_discount', null, null)
			.subscribe((platinumMemberSetting: SettingAPIResponse) => {
				if (platinumMemberSetting.success) {
					this.platinumMemberDiscount = platinumMemberSetting.setting.value;
				}
			});

		of(null).pipe(concatMap(() => this.userService.author$))
			.pipe(concatMap((author: IUser) => {
				this.author = author;
				this.getCustomer();
				return this.getPlans();
			})).subscribe((data: MemberRolesAPIResponse) => {
				if (data && data.success) {
					this.plans = data.roles;
				}

				this._loading.next(false);
			});
	}

	get loading$() {
		return this._loading.asObservable();
	}

	getPlans() {
		return of(null).pipe(
			switchMap(() => {
				if (this.plans.length > 0) {
					return of(null);
				}
				return this.memberRoleService.fetchMemberRoles().pipe(first());
			}),
		);
	}

	getCustomer() {
		this.customer = this.createNewCustomer();

		if (!this.author.stripeCustomerId) {
			return;
		}

		this.stripeService.getCustomer(this.author.stripeCustomerId).pipe(first())
			.subscribe(({ customer }: { customer: {id: string; invoice_settings: { default_payment_method: string }}}) => {
				if (customer?.id) {
					this.customer.customerId = customer.id;
					this.customer.paymentMethodId = customer.invoice_settings
						? customer.invoice_settings.default_payment_method
						: undefined;
				}
			});
	}

	select(type: string) {
		const dictionary = { basic: 'gold', premium: 'platinum' };

		const _type = dictionary[type] ? dictionary[type] : type;

		this.selectedPlanName =	_type;
		this.plan = this._getPlanByName(_type);
	}

	_getPlanByName(name: string) {
		const { plans } = this;
		if (!plans || !plans.length) return null;

		return plans.find(({ roleName }) => roleName.toLowerCase() === name);
	}

	createNewCustomer(): IStripeCustomer {
		const _c = {
			customerId: '',
			email: this.author.username,
			name: `${this.author.firstName} ${this.author.lastName}`,
			paymentMethodId: '',
			referral: window.Rewardful?.referral,
		};

		if (_c.referral === '') delete _c.referral;

		return _c;
	}

	upgrade() {
		return this.stripeService.upgradeSubscription(this.customer, this.plan);
	}

	create(paymentMethodId: string) {
		this.customer.paymentMethodId = paymentMethodId;
		return this.stripeService.createSubscription(this.customer, this.plan, !this.author.stripeCustomerId);
	}
}
