<app-modal [id]="modalId" [maskClose]="false">
	<aside class="modal cart-modal">
		<div class="modal-body">
			<div *ngIf="sent">
				<div class="header">
					<div class="title-wrapper">
						<div class="title">Request Submitted!</div>
						<div class="subtitle">Someone will get back to you shortly.</div>
					</div>
					<div class="button regular close" (click)="close()"><img src="../../../assets/img/icon-close.svg" /></div>
				</div>
				<div *ngIf="!loading">
					<div class="row">
						<div class="col-md-8">
							<div class="sent-content">
								<p>Some other options to get support:</p>
								<ul class="">
									<li>Visit our <a href="javascript:;" (click)="goToSupport()">help center</a> to see support articles 
										and FAQs</li>
									<li>Read our <a href="javascript:;" (click)="goToBlog()">blog</a> for helpful how to articles and 
										interesting stories</li>
									<li>Join our <a href="javascript:;" (click)="joinFacebook()">Facebook Group</a> 
										to see what other Authors are saying</li>
								</ul>
							</div>
							<div class="footer">
								<button class="regular button solid-green submit-btn" type="button" (click)="close()">Close</button>
							</div>
						</div>
						<div class="col-md-4">
						</div>
					</div>
				</div>
			</div>
			<div *ngIf="!sent">
				<div class="header">
					<div class="title-wrapper">
						<div class="title">Support for Authors, from Real People</div>
						<div class="subtitle">We have dedicated, real humans to answer your questions. 
								We know your books are important and we’ll respond ASAP.</div>
					</div>
					<div class="button regular close" (click)="close()"><img src="../../../assets/img/icon-close.svg" /></div>
				</div>
				<app-spinner *ngIf="loading"></app-spinner>
				<div *ngIf="!loading">
					<div class="support-team-wrapper">
						<ul class="support-team">
							<li><img src="../../../assets/img/contact-form/image_1.png" alt="image_1.png"></li>
							<li><img src="../../../assets/img/contact-form/image_2.png" alt="image_2.png"></li>
							<li><img src="../../../assets/img/contact-form/image_3.png" alt="image_3.png"></li>
							<li><img src="../../../assets/img/contact-form/image_4.png" alt="image_4.png"></li>
							<li><img src="../../../assets/img/contact-form/image_5.png" alt="image_5.png"></li>
							<li><img src="../../../assets/img/contact-form/image_6.png" alt="image_6.png"></li>
							<li><img src="../../../assets/img/contact-form/image_7.png" alt="image_7.png"></li>
						</ul>
						<p class="support-team-description">Our dedicated author support team is ready to help!</p>
					</div>
					<div class="row">
						<div class="col-md-8">
							<div class="content">
								<form id="contactForm" class="form" [formGroup]="form" (ngSubmit)="onSubmit($event)">
									<app-form-input [input]="formComponents.email" [form]="form"></app-form-input>
									<app-form-input [input]="formComponents.name" [form]="form"></app-form-input>
									<app-form-input [input]="formComponents.message" [form]="form"></app-form-input>
								</form>
							</div>
							<div class="footer">
								<button form="contactForm" class="regular button solid-green submit-btn" type="submit">Submit</button>
								<a class="cancel-btn" (click)="close()">Cancel</a>
							</div>
						</div>
						<div class="col-md-4"></div>
					</div>
				</div>
			</div>
			
		</div>
		<div class="cart-modal-background" (click)="close()"></div>
	</aside>
</app-modal>
<app-error-modal [maskClose]="true" [modalId]="'error-modal'" [text]="errorText"></app-error-modal>
<app-success-modal [modalId]="'success-modal'" [text]="successText" [maskClose]="true"></app-success-modal>
