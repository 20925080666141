<div class="wrapper" [ngClass]="{'checkout-item': _variant === VARIANT_CHECKOUT_ITEM, 'cart-item': _variant === VARIANT_CART_ITEM}">
    <app-spinner *ngIf="loading"></app-spinner>
    <div class="container" *ngIf="!loading">
      <div class="item-contents flex">
        <div class="image-container">
          <img [src]="itemImage">
        </div>
        <div>
          <div class="item-details flex-grow-1">
            <div class="row">
              <p class="title">{{feature._book.title}}</p>
              <div class="prices">
                <div class="original-price" *ngIf="hasDiscount">{{ feature._siteAdvert.price | currency:'USD' }}</div>
                <div class="discounted-price">{{ paymentAmount | currency:'USD' }}</div>
              </div>
            </div>
            <ul class="expanded-details mt-3 mb-0">
              <li><b>Date:</b> {{ feature.endPubDate? 'Starting on' : '' }} {{pubDate}}</li>
            </ul>
            <div *ngIf="detailsExpanded">
              <ul class="expanded-details">
                <li><b>Site:</b> {{feature._site.name}}</li>
                <li><b>Genre Product:</b> {{feature._siteAdvert.name}}</li>
                <li><b>Book Price:</b> {{bookPrice}}</li>
              </ul>
              <ul class="expanded-details-prices" 
                *ngIf="hasValidPromoCode() || (_variant === VARIANT_CART_ITEM && hasSpeculativeDiscount) && hasDiscount">
                <li *ngFor="let detail of discountDetails">
                  <span>{{ detail.label }}</span>
                  <span>{{ detail.value | currency:'USD' }}</span>
                </li>
              </ul>
              <ul class="expanded-details-prices" *ngIf="hasDiscount">
                <li><b>Promo Price</b> <b>{{ paymentAmount | currency:'USD' }}</b></li>
              </ul>
            </div>
          </div>
          <div class="show-details-wrapper mt-3">
            <a class="show-details-link" (click)="toggleDetails()">{{detailsExpanded ? 'Collapse Details' : 'Show Details'}}</a>
            <a class="edit-link" (click)="editFeature()">Edit</a>
          </div>
        </div>
      </div>
      <div class="button regular close" (click)="openConfirmationModal()"><span class="icon">&#x00D7;</span></div>
    </div>
  </div>
  <app-confirm-modal
    [modalId]="modalId"
    [text]="confirmText"
    [buttonText]="buttonText"
    (confirmChoice)="onItemRemove($event)"
  ></app-confirm-modal>
<app-error-modal [modalId]="'cart-item-error-modal'" [text]="errorText"></app-error-modal>
