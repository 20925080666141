import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../core/services/data/user.service';

@Component({
	selector: 'app-confirm-auth',
	templateUrl: './confirm-auth.component.html',
})
export class ConfirmAuthComponent implements OnInit {
	constructor(
		private us: UserService,
		private router: Router,
	) { }

	ngOnInit(): void {
		setTimeout(() => {
			if (this.us.isAdminOrSuperAdmin()) {
				this.us.authorizeEditorialOwl(this.us.me, () => {});
			} else {
				this.router.navigate(['/login']).then();
			}
		}, 1000);
	}
}
