<app-modal [id]="modalId" [maskClose]="false">
  <aside class="modal">
    <div class="modal-body">
      <h2>{{_data.header}}</h2>
      <form [formGroup]="form" *ngIf="form" (ngSubmit)="sendMail($event)">
        <div *ngIf="!finished">
          <p>{{_data.text}}</p>
          <label [innerHtml]="_data.label"></label>
          <div class="inputs">
            <app-form-input [input]="mailForm.name" [form]="form"></app-form-input>
            <app-form-input [input]="mailForm.email" [form]="form"></app-form-input>
            <app-form-input [input]="mailForm.content" [form]="form"></app-form-input>
          </div>
        </div>
        <div *ngIf="finished" class="finish-text">Your inquiry has been sent.</div>
        <div class="buttons">
          <button *ngIf="finished" type="button" class="regular button solid-green" (click)="close()">OK</button>
          <button *ngIf="!finished" type="submit" class="regular button solid-green {{_data.buttonClass}}">
            <span *ngIf="!loading">{{_data.button}}</span>
            <span *ngIf="loading">Processing...</span>
          </button>
          <button *ngIf="!finished" type="button" class="regular button solid-red {{_data.cancelClass}}" (click)="close()">Cancel</button>
        </div>
      </form>
    </div>
  </aside>
  <div class="modal-background"></div>
</app-modal>
<app-error-modal [modalId]="'mf-error-modal'" [text]="errorText"></app-error-modal>
