<app-modal [id]="modalId" [maskClose]="false">
  <aside class="modal confirm-modal">
    <div class="modal-body" [ngClass]="{success: success}">
      <div class="header">
        <p class="title">{{title}}</p>
        <div class="button regular close" (click)="cancel()"><span class="icon">&#x00D7;</span></div>
      </div>
      <div class="content" [innerHtml]="text">
      </div>
      <div class="footer" *ngIf="_buttonText">
        <button class="button regular outline-grey cancel {{_buttonText.cancelClass}}" (click)="cancel()" *ngIf="_buttonText.cancel">{{_buttonText.cancel}}</button>
        <button class="button regular solid-red {{_buttonText.confirmClass}}" (click)="close()">{{_buttonText.confirm}}</button>
      </div>
    </div>
  </aside>
  <div class="confirm-modal-background"></div>
</app-modal>
