import { Injectable } from '@angular/core';
import Stripe from 'stripe';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import * as moment from 'moment';
import { ServerConnectionService } from './server-connection.service';
import { CoreModule } from '../core.module';
import { SubscriptionAPIResponse } from '../../types/responses';
import { StripePaymentIntentOptions } from '../../types/local';

@Injectable({
	providedIn: CoreModule,
})
export class StripeService {
	public cards: Array<any> = [
		{ amex: 'Amex', discover: 'Discover', mastercard: 'Mastercard', visa: 'Visa', diners: 'Diners Club', jcb: 'JCB', unionpay: 'UnionPay' },
	];

	constructor(private scs: ServerConnectionService) { }

	public getInstance(pk) {
		return new Stripe(pk, {
			apiVersion: '2022-11-15',
		});
	}

	// CUSTOMERS

	public getCustomer(customerId) {
		return this.scs.http$('GET', `/api/users/customers/${customerId}`, null, null);
	}

	public updateCustomer(customerId, paymentMethodId, shouldAttach, setDefault) {
		const body = { paymentMethodId, shouldAttach, setDefault };
		return this.scs.http$('PUT', `/api/users/customers/${customerId}`, null, body);
	}

	public updateCustomerInfo(customerId, customer) {
		const body = customer;
		return this.scs.http$('PUT', `/api/stripe/customers/${customerId}`, null, body);
	}

	// PAYMENT METHODS

	public getCards(customerId) {
		return this.scs.http$('GET', `/api/users/cards/${customerId}`, null, null);
	}

	public defaultCustomerPaymentMethod(customerId, paymentMethodId) {
		const body = { paymentMethodId };
		return this.scs.http$('POST', `/api/users/cards/default/${customerId}`, null, body);
	}

	public removeCard(paymentMethodId) {
		return this.scs.http$('DELETE', `/api/users/cards/${paymentMethodId}`, null, null);
	}

	// SUBSCRIPTIONS

	public createSubscription(customer, plan, createCustomer, stripeCoupon = null) {
		const body = { customer, plan, createCustomer, stripeCoupon };
		return this.scs.http$('POST', '/api/subscriptions/', null, body);
	}

	public upgradeSubscription(customer, plan) {
		const body = { customer, plan };
		return this.scs.http$('POST', '/api/subscriptions/upgrade', null, body);
	}

	public retrySubscriptionPayment(subscriptionId: string) {
		return this.getSubscriptionsById(subscriptionId)
			.pipe(
				switchMap((result: SubscriptionAPIResponse) => {
					if (result.success && this.isSubscriptionWithDeclinedPayment(result.subscription)) {
						return this.scs.http$(
							'POST',
							'/api/subscriptions/retry-payment',
							null,
							{ stripeSubscriptionId: result.subscription.stripeSubscriptionId },
						);
					}
					return of(null);
				}),
			);
	}

	public cancelSubscription(customer) {
		const body = { customer };
		return this.scs.http$('POST', '/api/subscriptions/cancel', null, body);
	}

	public getSubscriptions(username) {
		return this.scs.http$('GET', `/api/subscriptions/${username}`, null, null);
	}

	public getSubscriptionsById(id) {
		return this.scs.http$('GET', `/api/subscriptions/getById/${id}`, null, null);
	}

	public getPromotionCodesList() {
		return this.scs.http$('GET', '/api/stripe/promotion-codes/list', null, null);
	}

	public getCoupon(coupon) {
		const body = { coupon };
		return this.scs.http$('POST', '/api/stripe/coupons/retrive', null, body);
	}

	// Stripe customer
	public createStripeCustomer(email) {
		const body = {
			receipt_email: email,
		};

		return this.scs.http$('POST', '/api/users/create-stripe-customer', null, body);
	}

	public getCustomerPaymentMethods(stripeCustomerId) {
		const body = {
			stripeCustomerId,
		};

		return this.scs.http$('POST', '/api/stripe/customers/paymentMethods', null, body);
	}

	// Stripe payment intent
	public getPaymentIntentClientSecret(paymentIntentOptions, orderId) {
		const body = {
			paymentIntentOptions,
			orderId,
		};

		return this.scs.http$('POST', '/api/stripe/paymentIntent/client-secret', null, body);
	}

	public cancelPendingPaymentIntents(details) {
		return this.scs.http$('POST', '/api/stripe/paymentIntent/cancel', null, details);
	}

	public createPaymentIntentOptions(adjustedAmount: number, metadata: any, description: string, customer = null): StripePaymentIntentOptions {
		const opts: StripePaymentIntentOptions = {
			amount: Math.round(adjustedAmount * 100), // Make sure we dont end up with 13680.000000000002 as a value
			currency: 'usd',
			automatic_payment_methods: { enabled: true },
			setup_future_usage: 'on_session',
			metadata,
			description,
		};

		if (customer) {
			opts.customer = customer;
		}

		return opts;
	}

	public updatePaymentIntentAmount(paymentIntentId: string, amount: number) {
		const body = {
			paymentIntentId,
			amount,
		};

		return this.scs.http$('POST', '/api/stripe/paymentIntent/update-amount', null, body);
	}

	public updatePaymentIntentPaymentMethod(paymentIntentId: string, paymentMethodId: string) {
		const body = {
			paymentIntentId,
			paymentMethodId,
		};

		return this.scs.http$('POST', '/api/stripe/paymentIntent/update-payment-intent', null, body);
	}

	public updatePaymentIntentCustomer(paymentIntentId: string, customer: string) {
		const body = {
			paymentIntentId,
			customer,
		};

		return this.scs.http$('POST', '/api/stripe/paymentIntent/update-customer', null, body);
	}

	public getPaymentIntentFromToken(stripePaymentIntentClientSecret: string): string {
		const [a, b] = stripePaymentIntentClientSecret.split('_');
		const _pi = [a, b].join('_');

		if (_pi.includes('pi_')) {
			return _pi;
		}

		throw new Error('Invalid payment intent client secret');
	}

	private isSubscriptionWithDeclinedPayment(subscription) {
		const DATE_TOLERANCE = 15;
		const isRenewalDateInTolerance = moment().isBefore(
			moment(subscription.renewalDate).add(DATE_TOLERANCE, 'days'),
		);

		return subscription.currentStatus === 'expired'
		&& subscription.paymentStatus === 'declined'
		&& isRenewalDateInTolerance;
	}
}
