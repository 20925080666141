import { Injectable } from '@angular/core';
import { ReplaySubject, Subject, merge, of, timer } from 'rxjs';
import { concatMap, switchMap, startWith } from 'rxjs/operators';
import * as moment from 'moment';
import { ServerConnectionService } from '../server-connection.service';
import { CoreModule } from '../../core.module';
import { AnalyticsService } from '../analytics.service';
import { UtmTrackingService, UTM_PARAMS } from '../utm-tracking.service';
import { UserService } from './user.service';
import { PromoStackPurchaseService } from './promo-stack-purchase.service';
import { GenreService } from './genre.service';

import { IPopulatedGenres } from '../../../types/populated';
import { PromoCodeService } from './promo-code.service';

const BASE_ORDER_URI = '/api/order';
const ENDPOINT_ADD_ITEM_TO_CART = `${BASE_ORDER_URI}/add-to-cart`;
const ENDPOINT_REMOVE_ITEM_FROM_CART = `${BASE_ORDER_URI}/remove-from-cart`;
const ENDPOINT_FETCH_EXISTING_ORDER = `${BASE_ORDER_URI}/fetch-existing-order`;
const ENDPOINT_COMPLETE_CART_ORDER = `${BASE_ORDER_URI}/complete-cart-order`;
const ENDPOINT_FETCH_ORDER_BY_ID = `${BASE_ORDER_URI}/fetch-order`;
const ENDPOINT_CREATE_STRIPE_PAYMENT = `${BASE_ORDER_URI}/create-stripe-payment`;
const ENDPOINT_COMPLETE_FREE_ORDER = `${BASE_ORDER_URI}/complete-free-order`;
const ENDPOINT_CHECK_ORDER_ADVERT_INVENTORY = `${BASE_ORDER_URI}/check-orders-advert-inventory`;
const ENDPOINT_FETCH_EXISTING_GUEST_ORDER = `${BASE_ORDER_URI}/fetch-existing-guest-order`;
const ENDPOINT_FETCH_ALL_AUTHOR_ORDERS = `${BASE_ORDER_URI}/fetch-all-author-orders`;
export const ENDPOINT_AUTHOR_ORDERS_EXPORT = `${BASE_ORDER_URI}/author-orders-export`;

const LOCAL_STORAGE = window.localStorage;
const COOKIE_ORDER_ID = 'orderId';
const ITEM_TYPE_FEATURE = 'featuredBook';
const DEFAULT_PROMOTION_DAY_DIFFERENCE = 30;

const PROMO_TYPE_PERCENT = 'percent';
const PROMO_TYPE_AMOUNT = 'amount';
const PROMO_TYPES = [PROMO_TYPE_PERCENT, PROMO_TYPE_AMOUNT];

interface DataStore {
	order: any;
	editingItemId: string;
}

@Injectable({
	providedIn: CoreModule,
})
export class OrderService {
	private genres;

	private _orderRefresh = new Subject();

	private _orderStore = <ReplaySubject<Object>> new ReplaySubject(1);

	private _cartUpdating = new ReplaySubject<boolean>();

	private _cartVisibleStatusStore = <ReplaySubject<Boolean>> new ReplaySubject(1);

	private _editingItemIdStore = <ReplaySubject<String>> new ReplaySubject(1);

	private _gaOrderItems = [];

	private dataStore: DataStore = {
		order: {},
		editingItemId: null,
	};

	constructor(
		private scs: ServerConnectionService,
		private as: AnalyticsService,
		private utms: UtmTrackingService,
		private us: UserService,
		private promoStackPurchaseService: PromoStackPurchaseService,
		private genreService: GenreService,
		private pcs: PromoCodeService,
	) {
		this.genreService.getGenres().subscribe((genres: IPopulatedGenres[]) => {
			this.genres = genres;
		});
	}

	get orderId() {
		return this.dataStore.order?._id;
	}

	get cartUpdating$() {
		return this._cartUpdating.asObservable();
	}

	get orderStore$() {
		return this._orderStore.asObservable();
	}

	get orderRefresh() {
		return this._orderRefresh.asObservable();
	}

	get cartVisibleStatus$() {
		return this._cartVisibleStatusStore.asObservable();
	}

	get editingItemId$() {
		return this._editingItemIdStore.asObservable();
	}

	public setCartNotVisible() {
		this.setCartVisibleStatus(false);
	}

	public setCartVisible() {
		this.setCartVisibleStatus(true);
	}

	public setEditingItemId(id) {
		this.dataStore.editingItemId = id;
		this.setItemUpdate();
	}

	public clearStoreOrder() {
		this._orderStore.next({});
	}

	public handleWebSocket(data) {
		if (data && data.id && data.action === 'refresh' && data.id === this.dataStore.order?._id) {
			this._orderRefresh.next(data.id);
		}
	}

	getSubscriptionFromOrder() {
		return this.dataStore.order.items.find((item) => item.itemType === 'subscription');
	}

	public clearOrder() {
		this.clearOrderCookie();
		this.clearStoreOrder();
	}

	public clearOrderCookie() {
		const rawCookie = LOCAL_STORAGE.getItem(COOKIE_ORDER_ID);
		if (rawCookie) {
			LOCAL_STORAGE.removeItem(COOKIE_ORDER_ID);
		}
		return true;
	}

	public getOrderCookie() {
		const rawCookie = LOCAL_STORAGE.getItem(COOKIE_ORDER_ID);
		if (!rawCookie) {
			return null;
		}
		const cookie = JSON.parse(rawCookie);
		const now = new Date();
		// remove the cookie if it hasn't been updated in 15 days
		if (now.getTime() > cookie.expiry) {
			LOCAL_STORAGE.removeItem(COOKIE_ORDER_ID);
			return null;
		}
		return cookie.value;
	}

	public checkDuplicateOrder(email: string, orderId: string) {
		return this.scs.http$('POST', `${BASE_ORDER_URI}/duplicate-order`, null, { email, suspectOrderId: orderId });
	}

	public addUserToOrder(userId: string) {
		const orderId = this.dataStore?.order?._id;
		return this.scs.http$('POST', `${BASE_ORDER_URI}/add-user`, null, { orderId, userId });
	}

	public updateOrderDataStore(order) {
		this.dataStore.order = order;
		this.setOrder();
		this.setEditingItemId(null);
		if (order?.orderCookie) {
			this.setOrderCookie(order.orderCookie);
		}
	}

	public removeSubscriptionFromCart() {
		const subscription = this.getSubscriptionFromOrder();
		if (!subscription) {
			return of(null);
		}

		return this.removeFromCart(subscription);
	}

	public applyMemberDiscount(userId: string) {
		// Wait for the order to be merged, then apply the member discount.
		// There might be cases where order doesnt get merged?

		const timeLimit$ = timer(5 * 1000).pipe(startWith(0));

		return merge(this.orderStore$, timeLimit$)
			.pipe(switchMap((orderId) => {
				const body = {
					userId,
					orderId: this.dataStore.order._id,
				};

				return this.scs.http$('POST', '/api/order/apply-member-discount', null, body);
			})).pipe(concatMap((result: any) => {
				if (result.success) {
					this.pcs.triggerUpdatePromoCode();
					this.refreshCart();
					return of(result);
				}

				return of(null);
			}));
	}

	public addSubscriptionToCart(planId, userInfo) {
		if (!this.us?.me?._id) {
			return this.us.createTempUser(userInfo)
				.pipe(concatMap((tempUser) => this.scs.http$('POST', '/api/order/add-subscription-to-cart', null, {
					planId,
					orderCookie: this.getOrderCookie(),
					userId: null,
					tempUser: tempUser.user,
				})));
		}

		const body = {
			planId,
			orderCookie: this.getOrderCookie(),
			userId: this.us?.me?._id,
		};
		return this.scs.http$('POST', '/api/order/add-subscription-to-cart', null, body);
	}

	public addPromoStackToCart(id) {
		const { purchaserInfo } = this.promoStackPurchaseService;

		return new Promise((resolve) => this.scs.http$(
			'POST',
			'/api/order/add-promo-stack-to-cart',
			null,
			{ promoStackId: id, orderCookie: this.getOrderCookie(), userId: this.us?.me?._id, purchaserInfo },
		).subscribe((data: any) => {
			this.updateOrderDataStore(data.order);
			resolve(data);
		}));
	}

	public addToCart(featuredBook, user, mongoId = null) {
		this._cartUpdating.next(true);
		const featuredBookData = {
			...featuredBook,
			// Pretty sure if this is null the implication is that it needs to be created
			itemMongoId: mongoId || this.dataStore.editingItemId,
		};
		const cookie = this.getOrderCookie();
		if (cookie) {
			featuredBookData.orderCookie = cookie;
		}
		featuredBookData._owner = user && user._id ? user._id : null;
		featuredBookData.purchaserInfo.loggedIn = !!((user && user._id));
		return new Promise((resolve, reject) => {
			this.scs.http$('POST', ENDPOINT_ADD_ITEM_TO_CART, null, featuredBookData).subscribe(
				(result: any) => {
					if (result.success && result.order) {
						this.updateOrderDataStore(result.order);
						const { _site, _siteAdvert, _book, purchaseSource } = featuredBook;
						// Can be deprecated after GA4 is implemented and good to go.
						const listName = `${_site.name} for Authors`;
						const productData = {
							id: _siteAdvert._id,
							name: `${_site.name} ${_siteAdvert.name} Feature`,
							category: `${_site.name} / ${_siteAdvert.name}`,
							quantity: 1,
							price: `${_siteAdvert.price}`,
							dimension1: _book.author,
							dimension2: user ? user._id : '',
						};
						this.as.trackAddToCart(listName, productData);
						// End of deprecated code.

						const { seoSlug, name, parentGenre } = _siteAdvert;
						const ecommerceItem = this.as.generateAnalyticsEcommerceObject(
							seoSlug,
							name,
							_site.name,
							parentGenre,
							featuredBook.paymentAmount,
							1,
							purchaseSource || _site.name,
							purchaseSource,
						);

						this.as.trackAddToCart2(featuredBook.paymentAmount, [ecommerceItem]);
						this._cartUpdating.next(false);
						resolve(result);
						this.pcs.triggerUpdatePromoCode();
					} else {
						reject(result);
					}
				},
			);
		});
	}

	_checkForMatchingExistingItem(item) {
		const { _id } = item;

		if (!this.dataStore?.order?.items) {
			return false;
		}

		const duplicate = this.dataStore.order.items.find((orderItem) => {
			const { item: orderItemData } = orderItem;
			return orderItemData._id === _id;
		});

		return !!duplicate;
	}

	public removeFromCart(item) {
		const { _id, type, features } = item;
		const userId = this.us.me?._id;
		const cookie = this.getOrderCookie();
		const itemData = { _id, type, features, userId, orderCookie: cookie || null };

		return new Promise((resolve, reject) => {
			this.scs.http$('POST', ENDPOINT_REMOVE_ITEM_FROM_CART, null, itemData).subscribe((result: any) => {
				if (result.success) {
					this.dataStore.order = result.order;
					this.setOrder();
					if (item.type === 'promoStack') {
						this.handlePromoStackRemoveAnalytics(item);
					}

					if (item.type === 'feature') {
						this.handleFeaturRemoveAnalytics(item, userId);
					}

					resolve(result);
					this.pcs.triggerUpdatePromoCode();
				} else {
					reject(result);
				}
			});
		});
	}

	handlePromoStackRemoveAnalytics(promoStack) {
		const ecommerceItem = this.as.generateAnalyticsEcommerceObject(
			promoStack.name,
			promoStack.name,
			'Various',
			undefined,
			promoStack.price,
			1,
			'Promo Stacks',
			'Promo Stacks',
		);

		this.as.trackRemoveFromCart2(null, ecommerceItem, 'Promo Stacks');
	}

	handleFeaturRemoveAnalytics(featuredBook, userId) {
		const { _site, _siteAdvert, _book, paymentAmount } = featuredBook;
		// Can be deprecated after GA4 is implemented and good to go.
		const listName = `${_site.name} for Authors`;
		const productData = {
			id: _siteAdvert._id,
			name: `${_site.name} ${_siteAdvert.name} Feature`,
			category: `${_site.name} / ${_siteAdvert.name}`,
			quantity: 1,
			price: `${_siteAdvert.price}`,
			dimension1: _book.author,
			dimension2: userId,
		};
		this.as.trackRemoveFromCart(listName, productData);
		// End of deprecated code.

		const ecommerceItem = this.as.generateAnalyticsEcommerceObject(
			_siteAdvert.seoSlug,
			_siteAdvert.name,
			_site.name,
			_siteAdvert.parentGenre,
			paymentAmount,
			1,
		);

		this.as.trackRemoveFromCart2(null, ecommerceItem, _site.seoSlug);
	}

	public parseAdvertInventoryConflictError(rawConflictData, order) {
		// Default values for the error object
		const errorObject = {
			text: 'Something went wrong. Please try again later.',
			title: 'Whoops!',
		};

		if (rawConflictData && rawConflictData.length && order) {
			const conflictData = rawConflictData[0];
			if (
				conflictData
				&& conflictData._siteAdvert
				&& conflictData.cartFeatureCountForSiteAdvert
				// Only check for this key on the object since value could be zero
				// eslint-disable-next-line no-prototype-builtins
				&& conflictData.hasOwnProperty('availableInventory')
			) {
				const {
					_siteAdvert,
					cartFeatureCountForSiteAdvert,
					availableInventory,
				} = conflictData;
				const featuresToMove = cartFeatureCountForSiteAdvert - availableInventory;
				const isSingle = availableInventory === 1;
				const featureWithConflictAdvert = order.items.filter(({ item }) => item?._siteAdvert?._id === _siteAdvert)[0];

				if (featureWithConflictAdvert) {
					const {
						item: {
							_siteAdvert: { name: siteAdvertName = '' } = {},
							_site: { name: siteName = '' } = {},
						},
					} = featureWithConflictAdvert;
					errorObject.text = `There are ${cartFeatureCountForSiteAdvert} features in your cart for promotion type 
					${siteName} ${siteAdvertName} on the same promo date. But there ${isSingle ? 'is' : 'are'} 
					only ${availableInventory} ${isSingle ? 'spot' : 'spots'} left. Please move at 
					least ${featuresToMove} ${featuresToMove === 1 ? 'feature' : 'features'} to a new promotion date.`;
				} else {
					const promoStackWithConflict = order.items.filter(({ item }) => item._promoStack?.siteAdverts.includes(_siteAdvert))[0];

					if (promoStackWithConflict) {
						const {
							item: { _promoStack: { name: promoStackName = '' } = {} },
						} = promoStackWithConflict;
						errorObject.text = `There are ${cartFeatureCountForSiteAdvert} promo stacks in your cart for  
						${promoStackName} on the same promo date. But there ${isSingle ? 'is' : 'are'} only ${availableInventory} 
						${isSingle ? 'spot' : 'spots'} left. Please move at least ${featuresToMove} 
						${featuresToMove === 1 ? 'feature' : 'features'} to a new promotion date.`;
					}
				}
			}
		}
		return errorObject;
	}

	public fetchExistingOrder(userId = null) {
		let orderCookie = null;
		if (!userId) {
			orderCookie = this.getOrderCookie();
			return this.fetchExistingGuestOrder(orderCookie);
		}
		return this.fetchExistingUserOrder(userId);
	}

	public fetchOrderByID(orderId) {
		return this.scs.http$('GET', `${ENDPOINT_FETCH_ORDER_BY_ID}/${orderId}`, null, null);
	}

	public refreshCart() {
		this.fetchOrderByID(this.dataStore.order._id).subscribe((result: any) => {
			if (result.success) {
				this.dataStore.order = result.order;
				this.setOrder();
			}
		});
	}

	public checkOrderForThirtyDayViolation(order) {
		let error = null;
		if (order && order.items) {
			order.items.map(({ item: currentItem, itemType: currentItemType }) => {
				if (currentItemType === ITEM_TYPE_FEATURE) {
					const feature = currentItem;
					const duplicateFeatures = order.items.filter(({ item, itemType }) => {
						if (itemType === ITEM_TYPE_FEATURE) {
							const { _book, _siteAdvert } = item;
							return (
								_book._id === feature._book._id
                && _siteAdvert._id === feature._siteAdvert._id
							);
						}

						return false;
					});
					if (duplicateFeatures.length === 2) {
						const firstPubDate = moment(duplicateFeatures[0].item.pubDate, 'YYYYMMDD');
						const secondPubDate = moment(duplicateFeatures[1].item.pubDate, 'YYYYMMDD');
						const daysBetweenPubDates = Math.abs(firstPubDate.diff(secondPubDate, 'days'));
						if (daysBetweenPubDates <= DEFAULT_PROMOTION_DAY_DIFFERENCE) {
							error = {
								errorText: 'Your promotions are too close together.'
                  + ' Please make sure there are more than 30 days between your promotions.',
							};
						}
					}
				}

				return null;
			});
		}
		return error;
	}

	public completeCartOrder(completedOrder) {
		const utmTracking = this.buildUTMTrackingParams();
		const mergedOrder = { ...completedOrder, tracking: utmTracking };
		return this.scs.http$('POST', ENDPOINT_COMPLETE_CART_ORDER, null, mergedOrder);
	}

	public createStripePayment(completedOrderObj) {
		const utmTracking = this.buildUTMTrackingParams();
		const mergedOrder = { ...completedOrderObj, tracking: utmTracking };
		return this.scs.http$('POST', ENDPOINT_CREATE_STRIPE_PAYMENT, null, mergedOrder);
	}

	public completeFreeCartOrder(completedOrderObj) {
		const utmTracking = this.buildUTMTrackingParams();
		const mergedOrder = { ...completedOrderObj, tracking: utmTracking };
		return this.scs.http$('POST', ENDPOINT_COMPLETE_FREE_ORDER, null, mergedOrder);
	}

	public checkOrderSiteInventory(orderId) {
		return this.scs.http$('GET', `${ENDPOINT_CHECK_ORDER_ADVERT_INVENTORY}/${orderId}`, null, null);
	}

	public fetchAllAuthorOrders(userId) {
		return this.scs.http$('GET', `${ENDPOINT_FETCH_ALL_AUTHOR_ORDERS}/${userId}`, null, null);
	}

	public initiateCheckout(orderId) {
		if (!orderId) return of(null);

		return this.scs.http$('GET', `/api/order/initiate-checkout/${orderId}/`, null, null);
	}

	public calculateOrderTotal() {
		const { order } = this.dataStore;
		let orderTotalAmount = 0;
		if (order?.items?.length) {
			const { items } = order;
			// Loop through all the cart items to calculate the total order amount
			items.forEach((item) => {
				const { item: _item } = item;
				if (item.itemType === 'featuredBook') {
					orderTotalAmount += this.totalFeature(_item);
				}

				if (item.itemType === 'promoStack') {
					orderTotalAmount += _item.paymentAmount;
				}

				if (item.itemType === 'subscription') {
					orderTotalAmount += _item.paymentAmount;
				}
			});
		}

		return order?.total > 0 ? order.total : orderTotalAmount;
	}

	/**
   * Helper function to retrieve and build the saved UTM params from the purchaser's cookies
   */
	private buildUTMTrackingParams() {
		const utmTracking = {};
		UTM_PARAMS.forEach((utmParam) => {
			const utmValue = this.utms.getUTMParamFromCookie(utmParam);
			if (utmValue) {
				utmTracking[utmParam] = utmValue;
			}
		});
		return utmTracking;
	}

	private setOrder() {
		this._orderStore.next({ ...this.dataStore.order });
	}

	private setCartVisibleStatus(status) {
		this._cartVisibleStatusStore.next(status);
	}

	private setItemUpdate() {
		this._editingItemIdStore.next(this.dataStore.editingItemId);
	}

	private fetchExistingUserOrder(userId) {
		const orderCookie = this.getOrderCookie();
		return this.scs.http$('GET', `${ENDPOINT_FETCH_EXISTING_ORDER}/${userId}/${orderCookie}`, null, null).subscribe((result: any) => {
			if (result.success) {
				this.dataStore.order = result.order;
				this.setOrder();
			}
		});
	}

	private fetchExistingGuestOrder(orderCookie) {
		return this.scs.http$('GET', `${ENDPOINT_FETCH_EXISTING_GUEST_ORDER}/${orderCookie}`, null, null).subscribe((result: any) => {
			if (result.success) {
				this.dataStore.order = result.order;
				if (result.order && result.order.orderCookie) {
					this.setOrderCookie(result.order.orderCookie);
				}
				this.setOrder();
			}
		});
	}

	private setOrderCookie(orderCookie) {
		LOCAL_STORAGE.setItem(
			COOKIE_ORDER_ID,
			JSON.stringify(orderCookie),
		);
	}

	private totalFeature(feature) {
		const featureHasValidPromo = this.featureHasValidPromoCode(feature);
		const featureHasValidAdvert = this.featureHasValidSiteAdvert(feature);
		const { _siteAdvert } = feature;

		if (featureHasValidPromo && featureHasValidAdvert) {
			// Feature has a promo code, so we take that into account for the order's totalAmount
			return _siteAdvert.price - feature.discountAmount;
		}
		if (featureHasValidAdvert) {
			// No promo code, just add the original siteAdvert price
			return _siteAdvert.price;
		}

		return 0;
	}

	private featureHasValidPromoCode(feature) {
		return (
			feature
      && feature._promoCode
      && feature._promoCode.discountType
      && PROMO_TYPES.includes(feature._promoCode.discountType)
		);
	}

	private featureHasValidSiteAdvert(feature) {
		return (
			feature
      && feature._siteAdvert
      && feature._siteAdvert.price
		);
	}
}
