import { Injectable } from '@angular/core';
import { CoreModule } from '../../core.module';
import { ServerConnectionService } from '../server-connection.service';

@Injectable({
	providedIn: CoreModule,
})
export class SailthruService {
	constructor(
		private scs: ServerConnectionService,
	) {}

	public joinSailthruList(body) {
		return this.scs.http$('POST', '/api/sailthru/join-list', null, body);
	}

	public udpateAuthor(body) {
		return this.scs.http$('POST', '/api/sailthru/user/update', null, body);
	}
}
