<div class="form-line" [formGroup]="form">
  <div class="col-25">
    <label *ngIf="input.controlType !== 'checkbox'" [attr.for]="input.key"
      [ngClass]="{'form-error': !isValid && !isUntouched}">
      {{input.label}} <app-tooltip-card *ngIf="input.tooltip" [label]="input.tooltip" [text]="input.tooltipText"></app-tooltip-card>
      <span class="required" *ngIf="input.required"> * </span>
      <span *ngIf="!isValid && !isUntouched"> - {{getError()}}</span>
    </label>
    <label [attr.for]="input.key" *ngIf="input.controlType ==='checkbox' && !input.reverseInput">{{input.label}}</label>
    <span *ngIf="input.infoLabel">{{input.infoLabel}}</span>
  </div>
  <div [ngSwitch]="input.controlType" class="col-50 col-sm-100">
    <input autocomplete="new-password" autocorrect="off" autocapitalize="off" spellcheck="false" *ngSwitchCase="'text'"
      [formControlName]="input.key" [placeholder]="input.placeholder" [id]="input.key" [type]="input.type"
      [ngClass]="{'input-with-button': input.buttonSymbol, 'addon': input.addonSymbol, 'withDelete' : input.delete}" (blur)="blurEvent()" 
      [min]="input.min" [max]="input.max" (change)="preventOutOfRange($event)" />
    <div class="button regular input-button solid-green" *ngIf="input.buttonSymbol" (click)="inputButtonClicked()">
      {{input.buttonSymbol}}</div>
    <ng-select #ngSelect *ngSwitchCase="'select'" [items]=input.options bindValue="value" bindLabel="key"
      (change)="onSelectChange($event)">
    </ng-select>
    <span class="deleteButton" (click)="deleteInput(input.key)" *ngIf="input.delete">
      &#10006;
    </span>
    <app-datepicker *ngSwitchCase="'date'" (dateChange)="onDateChanged($event)" [startDate]="selDate"></app-datepicker>
    <!--<input *ngSwitchCase="'checkbox'" type="checkbox" width='24px' [formControlName]="input.key" [id]="input.key">-->
    <div *ngSwitchCase="'checkbox'" class="toggle" (click)="toggled()">
      <div *ngIf="input.reverseInput">
        <input class="toggle-input" [formControlName]="input.key" [id]="input.key">
        <button type="button" class="toggle-btn" [disabled]="input.disabled"
          [ngClass]="{'toggle-btn-on': toggleOn, 'toggle-btn-off': !toggleOn}" [attr.aria-expanded]="toggleOn"
          [id]="input.key"></button>
        <label [attr.for]="input.key" *ngIf="input.controlType ==='checkbox'">{{input.label}}</label>
      </div>
      <div *ngIf="!input.reverseInput">
        <!-- <label [attr.for]="input.key" *ngIf="input.controlType =='checkbox'">{{input.label}}</label> -->
        <input class="toggle-input" [formControlName]="input.key" [id]="input.key">
        <button type="button" class="toggle-btn" [disabled]="input.disabled"
          [ngClass]="{'toggle-btn-on': toggleOn, 'toggle-btn-off': !toggleOn}" [attr.aria-expanded]="toggleOn"
          [id]="input.key"></button>
      </div>
    </div>
    <textarea *ngSwitchCase="'textarea'" [formControlName]="input.key"  [maxlength]="input.maxLength">{{input.value}}</textarea>
    <div *ngSwitchCase="'radio'" [id]="input.key">
      <div *ngFor="let option of input.options">
        <input type="radio" [formControlName]="input.key" [id]="option.id" [value]="option.value" />
        <label class="radio-option" [attr.for]="option.id">{{option.label}}</label>
      </div>
    </div>
    <div *ngSwitchCase="'time'" class="time">
      <input type="text" [formControlName]="input.key" [id]="input.key" [value]="input.value"
        (click)="toggleTimePicker($event)" />
      <div class="time-container" [ngStyle]="{display: showTimePicker ? 'block' : 'none'}">
        <div class="time-picker">
          <div class="up">
            <span class="icon button" (click)="changeHour(1)">&#xf365;</span>
            <span class="icon button" (click)="changeMin(5)">&#xf365;</span>
            <span class="icon button" (click)="changeAm()">&#xf365;</span>
          </div>
          <div class="numbers">
            <span>{{hour}}</span>
            <span class="colon">:</span>
            <span>{{min}}</span>
            <span>{{am}}</span>
          </div>
          <div class="up">
            <span class="icon button" (click)="changeHour(-1)">&#xf35f;</span>
            <span class="icon button" (click)="changeMin(-5)">&#xf35f;</span>
            <span class="icon button" (click)="changeAm()">&#xf35f;</span>
          </div>
        </div>
        <div class="time-background" (click)="hideTimePicker()"></div>
      </div>
    </div>
  </div>
  <p *ngIf="input.helper" class="helper-text">{{input.helper}}</p>
  <div *ngIf="input.addonSymbol" class="addonSymbol">{{input.addonSymbol}}</div>
</div>
