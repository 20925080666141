import { InputText } from '../../classes/input-text';

export class LoginForm {
	email = new InputText({
		key: 'email',
		label: 'Email Address',
		required: true,
		pattern: 'email',
	});

	password = new InputText({
		key: 'password',
		label: 'Password',
		required: true,
		type: 'password',
	});
}
