<div class="top-bar">
	<div class="content-container" [ngClass]="user && user._id ? '' : 'notLogged'">
		<div class="left-top-bar">
			<button type="button" class="regular button outline-green toggle-button mobile-menu" [ngClass]="authorDetached ? 'detached' : ''">
				<span class="hamburger">☰</span>
			</button>
			<div class="logo"><a routerLink="{{homeRedirectLink}}"><img [src]="'/assets/img/logos/logoFullWithe.png'" /></a></div>
		</div>
		<div *ngIf="isEmulated" class="isEmulated badge lg">
			Emulating User <div> {{ author?.username }}</div>
		</div>
		<div>
			<div class="right-top-bar">
				<app-discount-meter></app-discount-meter>
				<app-member-menu class="member-menu-badge"></app-member-menu>
				<div class="cart-wrapper" [ngClass]="user && user._id ? '' : 'guest-cart'">
					<a *ngIf="cartPurchaseEnabled" class="cart" (click)="handleCartClick()">
						<p class="cart-text">Cart</p>
						<img [src]="'/assets/img/cart.svg'">
						<div class="badge basic">{{orderItemCount}}</div>
					</a>
					<a *ngIf="cartPurchaseEnabled" class="cart-mobile" (click)="handleCartClick()">
						<img [src]="'/assets/img/cart.svg'">
						<div class="badge basic">{{orderItemCount}}</div>
					</a>
				</div>
				<div *ngIf="!user?._id" class="login-signup-links">
					<div class="login" [ngClass]="authorDetached ? '' : 'mobile'" ><a class="button" (click)="login()">Login</a></div>
					<span class="divider">|</span>
					<div class="sign-up" [ngClass]="authorDetached ? '' : 'mobile'" *ngIf="!user || !user._id">
						<a class="button" (click)="signUp()">Sign up</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
