import { Injectable } from '@angular/core';
import { CoreModule } from '../core.module';

@Injectable({
	providedIn: CoreModule,
})
export class EnvironmentService {
	constructor(
	) { }

	isProduction(): boolean {
		return window.location.hostname === 'secure.writtenwordmedia.com';
	}

	isLocal(): boolean {
		return window.location.hostname.includes('localhost');
	}

	isDevelopment(): boolean {
		return !this.isProduction() && !this.isLocal();
	}

	isNotProduction(): boolean {
		return !this.isProduction();
	}
}
