import { Component, OnInit, OnDestroy } from '@angular/core';
import { animate, transition, state, style, trigger } from '@angular/animations';
import { SupportService } from '../../core/services/data/support.service';
import { UserService } from '../../core/services/data/user.service';
import { ModalService } from '../../core/services/modal.service';

@Component({
	selector: 'app-support',
	templateUrl: './support.component.html',
	styleUrls: ['./support.component.scss'],
	animations: [
		trigger('openClose', [
			state('true', style({ height: '*' })),
			state('false', style({ height: '0px' })),
			transition('false <=> true', animate(250)),
		]),
		trigger('rotate', [
			state('true', style({ transform: 'rotate(90deg)' })),
			state('false', style({ transform: 'rotate(0)' })),
			transition('false <=> true', animate(250)),
		]),
	],
})
export class SupportComponent implements OnInit, OnDestroy {
	public questions;

	public changeData = {
		header: '',
		text: '',
		label: '',
		flatLabel: '',
		button: '',
		type: '',
		email: '',
		featureId: null,
	};

	private supports$;

	private me;

	constructor(
		private sups: SupportService,
		private uss: UserService,
		private ms: ModalService,
	) { }

	ngOnInit() {
		this.sups.update();
		this.supports$ = this.sups.supports$.subscribe((data: any) => {
			this.questions = data;
			this.questions.sort((a, b) => a.ordinal - b.ordinal);
			this.questions.forEach((item) => {
				item.showAnswer = false;
				item.answer = `<div class='support-answer'>${item.answer}</div>`;
			});
		});
		this.uss.fetchUser().then(
			(user) => {
				this.me = user;
				this.changeData = {
					header: 'Other Questions or Inquiries',
					text: 'Use the space below to send us any other questions or inquiries. Please be as detailed as possible so we can better '
          + 'answer whatever questions you might have, and include your email address so we can contact you.',
					label: 'Support Inquiry',
					flatLabel: 'Support Inquiry',
					button: 'Submit Support Inquiry',
					type: 'support_question',
					email: this.me.username,
					featureId: null,
				};
			},
			() => {
				this.changeData = {
					header: 'Other Questions or Inquiries',
					text: 'Use the space below to send us any other questions or inquiries. Please be as detailed as possible so we can better '
          + 'answer whatever questions you might have, and include your email address so we can contact you.',
					label: 'Support Inquiry',
					flatLabel: 'Support Inquiry',
					button: 'Submit Support Inquiry',
					type: 'support_question',
					email: '',
					featureId: null,
				};
			},
		);
	}

	openContact() {
		this.ms.open('mailTo');
	}

	ngOnDestroy() {
		this.supports$.unsubscribe();
	}
}
