import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { SitesService } from './data/sites.service';
import { SiteAdvertsService } from './data/site-adverts.service';
import { FeaturedBooksService } from './data/featured-books.service';
import { BooksService } from './data/books.service';
import { ToastService } from './toast.service';
import { CoreModule } from '../core.module';
import { OrderService } from './data/order.service';

@Injectable({
	providedIn: CoreModule,
})
export class WebSocketService {
	private websocket;

	private data = {};

	constructor(
		private ts: ToastService,
		private ss: SitesService,
		private sas: SiteAdvertsService,
		private fbs: FeaturedBooksService,
		private bs: BooksService,
		private os: OrderService,
	) {
		// detect environment
		if (window.location.hostname === 'localhost') {
			this.websocket = new WebSocket('ws://localhost:9090');
		} else if (window.location.hostname === 'alpha-author.writtenwordmedia.com') {
			this.websocket = new WebSocket('wss://alpha-websockets.writtenwordmedia.com:9090');
		} else {
			this.websocket = new WebSocket('wss://websockets.writtenwordmedia.com:9090');
		}

		this.websocket.onmessage = (evt) => {
			const data = JSON.parse(evt.data);
			this.parseData(data);
		};
	}

	public setData(type, data, overwrite) {
		if (overwrite) {
			this.data[type] = data;
		} else if (this.data[type]) {
			this.data[type] = _.union(this.data[type], data);
		} else {
			this.data[type] = data;
		}
	}

	public updateConnection() {
		if (this.websocket.readyState === this.websocket.OPEN) {
			this.websocket.send(JSON.stringify(this.data));
		} else {
			setTimeout(() => {
				this.updateConnection();
			}, 250);
		}
	}

	private parseData(data) {
		switch (data.type) {
			case 'sites':
				this.ss.handleWebSocket(data);
				break;
			case 'features':
				this.fbs.handleWebSocket(data);
				break;
			case 'books':
				this.bs.handleWebSocket(data);
				break;
			case 'order':
				this.os.handleWebSocket(data);
				break;
			default:
				break;
		}
	}
}
