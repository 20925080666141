import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { CoreModule } from '../core.module';

@Injectable({
	providedIn: CoreModule,
})
export class ToastService {
	private _queue = new ReplaySubject(1);

	private dataStore = [];

	constructor() { }

	get queue$() {
		return this._queue.asObservable();
	}

	addMessage(message) {
		this.dataStore.push(message);
		this._queue.next(this.dataStore);
		setTimeout(() => {
			this.dataStore.splice(0, 1);
			this._queue.next(this.dataStore);
		}, 5000);
	}
}
