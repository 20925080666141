<app-top-bar [authorDetached]="true"></app-top-bar>
<section>
  <h2>Help and Support</h2>
  <div class="content">
    <div *ngFor="let question of questions" class="block">
      <div class="question button">
        <span class="text" [innerHtml]="question.question | keepHtml" (click)="question.showAnswer = !question.showAnswer"></span>
        <span class="icon" [@rotate]="question.showAnswer ? true : false">&#xf363;</span>
      </div>
      <div class="answer" [@openClose]="question.showAnswer ? true : false" [innerHtml]="question.answer | keepHtml"></div>
    </div>
    <div class="other">
      <div class="title">Other Questions or Comments?</div>
      <div><button type="button" class="regular button" (click)="openContact()">Contact Us</button></div>
    </div>
  </div>
</section>
<app-mail-to [modalId]="'mailTo'" [data]="changeData"></app-mail-to>
