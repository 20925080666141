import { Component, Input } from '@angular/core';
import { UserService } from '../../core/services/data/user.service';

@Component({
	selector: 'app-member-badge',
	templateUrl: './member-badge.component.html',
	styleUrls: ['./member-badge.component.scss'],
})
export class MemberBadgeComponent {
	@Input() showUsername = true;

	@Input() showAvatar = true;

	@Input() customStyle = '';

	public user: any;

	public defaultProfileImage = '/assets/img/top-bar/member-badge/free-user-profile.svg';

	constructor(
		public userService: UserService,
	) {
		this.loadDefaultProfileImage();
	}

	get role() {
		const _role = this.userService.me?.memberRole?.roleName;

		return _role.toLowerCase();
	}

	loadDefaultProfileImage() {
		if (this.role === 'gold') {
			this.defaultProfileImage = '/assets/img/top-bar/member-badge/gold-user-profile.svg';
		} else if (this.role === 'platinum') {
			this.defaultProfileImage = '/assets/img/top-bar/member-badge/platinum-user-profile.svg';
		}
	}
}
