import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { OrderService } from '../../core/services/data/order.service';
import { UserService } from '../../core/services/data/user.service';
import { StripeService } from '../../core/services/stripe.service';

const DEFAULT_BOOK_COVER = '/assets/img/defaults/default_book_image_400w_simple.jpg';
const ORDER_STATUS_COMPLETED = 'completed';
const ORDER_STATUS_REFUNDED = 'refunded';
const ORDER_STATUS_PARTIAL_REFUND = 'partial-refund';
const ORDER_COMPLETED_STATUSES = [
	ORDER_STATUS_COMPLETED,
	ORDER_STATUS_REFUNDED,
	ORDER_STATUS_PARTIAL_REFUND,
];

@Component({
	selector: 'app-order-receipt',
	templateUrl: './order-receipt.component.html',
	styleUrls: ['./order-receipt.component.scss'],
})
export class OrderReceiptComponent implements OnInit, OnDestroy {
	public author;

	public orderId;

	public content = '<img'
    + ' style="margin-left: -28px;width:400px;"'
    + ' src="https://secure.writtenwordmedia.com/assets/img/logos/wwm_primary.png"'
    + ' />';

	public order;

	public subscription;

	public showButtons = true;

	public moment = moment;

	private author$;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private os: OrderService,
		private us: UserService,
		private str: StripeService,
	) { }

	ngOnInit() {
		this.orderId = this.route.snapshot.params.order;
		this.author$ = this.us.author$.subscribe((data: any) => {
			this.author = data;
		});
		if (this.orderId.includes('subscription')) {
			this.orderId = this.orderId.slice(12);
			this.str.getSubscriptionsById(this.orderId).subscribe((data: any) => {
				this.subscription = data.subscription;
			});
		} else {
			this.os.fetchOrderByID(this.orderId).subscribe((data: any) => {
				this.order = data.order;
				this.runOrder();
			});
		}
	}

	runOrder() {
		let paymentType = 'None';
		let paymentId = 'N/A';
		let amountPaid = '0';
		if (!this.order || this.order.items.length <= 0 || !ORDER_COMPLETED_STATUSES.includes(this.order.status)) {
			this.content += '<div style="padding-bottom:20px;border-bottom:1px dashed rgba(0,0,0,.6);margin-bottom:20px">Receipt unavailable, ';
			this.content += 'please contact support at <a style="color: #008af4" ';
			this.content += 'href="mailto:support@writtenwordmedia.com">support@writtenwordmedia.com</a></div>';
		} else {
			if (ORDER_COMPLETED_STATUSES.includes(this.order.status)) {
				if (this.order.totalAmount > 0) {
					amountPaid = this.order.totalAmount.toFixed(2);
					if (this.order.paymentInfo.stripeToken) {
						paymentType = 'Credit Card';
						paymentId = this.order.paymentInfo.stripeToken.id;
					} else {
						paymentType = 'PayPal';
						paymentId = this.order.paymentInfo.payPalToken.paymentID;
					}
				} else {
					paymentType = 'Coupon';
				}
			}

			this.content += `<div
               style="font-weight:400;font-size:28px;padding-bottom:20px;margin-bottom: 20px;border-bottom:1px dashed rgba(0,0,0,.6);">`
                + `Receipt of Payment
              </div>`;
			this.content += `<div>Full Name of Purchaser: ${this.order.purchaserInfo.firstName} ${this.order.purchaserInfo.lastName}</div>`;

			// Check if the user is present and has business info.
			if (this.order?.user?.businessInfo) {
				const { businessInfo } = this.order.user;
				// If business name is present, add it to the receipt
				if (businessInfo.name) {
					this.content += `<div>Business Name: ${businessInfo.name}</div>`;
				}
				if (businessInfo.taxId) {
					this.content += `<div>Tax ID/VAT ID: ${businessInfo.taxId}</div>`;
				}
				// If business address is present, start checking other location fields and add them to the receipt
				if (businessInfo.address && businessInfo.address.street1) {
					this.content += `<div>Address: ${businessInfo.address.street1}</div>`;
					// Only add second address field if first is present
					if (businessInfo.address.street2) {
						this.content += `<div>&emsp;&emsp;&emsp;&emsp;&nbsp;${businessInfo.address.street2}</div>`;
					}
					// Only add location info if all is present, and if address first field is present too
					if (businessInfo.city && businessInfo.state && businessInfo.postalCode && businessInfo.country) {
						this.content += `<div>&emsp;&emsp;&emsp;&emsp;&nbsp;${businessInfo.city}, `;
						this.content += `${businessInfo.state}, ${businessInfo.postalCode}</div>`;
						this.content += `<div>&emsp;&emsp;&emsp;&emsp;&nbsp;${businessInfo.country}</div>`;
					}
				}
			}
			const purchaseDate = moment(this.order.orderCompletedDate, 'YYYYMMDD').format('MM-DD-YYYY');
			this.content += `<div>Promotions Purchased: ${this.order.items.length}</div>`;
			if (purchaseDate) {
				this.content += `<div>Purchased Date: ${purchaseDate}</div>`;
			}
			this.content += `<div>Amount Paid: $${amountPaid}</div>`;
			this.content += `<div>Payment Type: ${paymentType}</div>`;
			this.content += `<div>Transaction ID: ${paymentId}</div>`;
			this.content += `<div>Order ID: ${this.order._id}</div><br/>`;
			this.content += '<div>The charge will show up as WRITTEN WORD MEDIA or PAYPAL *WRTTN WORD.</div><br/>';
			this.content += '<div style="margin-bottom: 20px;padding-bottom:20px;border-bottom:1px dashed rgba(0,0,0,.6);">'
          + 'This receipt is a valid receipt of purchase. If you have any billing related questions please email '
          + '<a style="text-decoration:none;color:#008af4" href="mailto:info@writtenwordmedia.com">info@writtenwordmedia.com</a>.</div>';
			// Loop through all the items in the order and append the necessary HTML
			this.order.items.forEach((item) => {
				if (item.itemType === 'featuredBook') {
					const promoDate = moment(item.item.pubDate, 'YYYYMMDD').format('MMMM Do, YYYY');
					const bookPrice = item.item.bookPriceDuringPromo > 0 ? `$${item.item.bookPriceDuringPromo.toFixed(2)}` : 'Free';

					this.content += '<div style="display: flex;margin-bottom: 20px;padding-bottom:20px;border-bottom:1px dashed rgba(0,0,0,.6);">';
					this.content += '<div style="display:flex; flex-direction: column; flex: 1;">';
					this.content += `<div style="font-weight:bold;">${item.item._book.title}</div><br/>`;
					this.content += `<div><a style="color:#7E7E7E">Promo Site: </a>${item.item._site.name}</div>`;
					this.content += `<div><a style="color:#7E7E7E">Genre / Product:</a> ${item.item._siteAdvert.name}</div>`;
					this.content += `<div><a style="color:#7E7E7E">Promo Date:</a>${promoDate}</div>`;
					this.content += `<div><a style="color:#7E7E7E">Book Price:</a>${bookPrice}</div>`;
					this.content += `<div><a style="color:#7E7E7E">Book Description:</a> ${item.item.description}</div>`;
					this.content += '</div>';
					this.content += `<div style="display: flex;flex-direction: column;flex 1;
					margin: 60px;align-items: center;justify-content: center">`;
					this.content += `<img style="width: 100px; height: auto" src="${item.item._book.amazonImageUrl || DEFAULT_BOOK_COVER}" />`;
					this.content += '</div>';

					this.content += '</div>';
				}

				if (item.itemType === 'promoStack') {
					const promoDate = moment(item.item.startDate, 'YYYYMMDD').format('MMMM Do, YYYY');
					const bookPrice = item.bookPriceDuringPromo > 0 ? `$${item.bookPriceDuringPromo.toFixed(2)}` : 'Free';

					this.content += '<div style="display: flex;margin-bottom: 20px;padding-bottom:20px;border-bottom:1px dashed rgba(0,0,0,.6);">';
					this.content += '<div style="display:flex; flex-direction: column; flex: 1;">';
					this.content += `<div style="font-weight:bold;">${item.item._book.title}</div><br/>`;
					this.content += `<div><a style="color:#7E7E7E">Promo Stack Name: </a>${item.item._promoStack.name}</div>`;
					this.content += `<div><a style="color:#7E7E7E">Promo Start Date: </a>${promoDate}</div>`;
					this.content += `<div><a style="color:#7E7E7E">Book Price: </a>${bookPrice}</div>`;
					this.content += `<div><a style="color:#7E7E7E">Book Description: </a> 
						${!item.item._book.description || item.item._book.description === 'undefined' ? '' : item.item._book.description}
					</div>`;
					this.content += `<div><a style="color:#7E7E7E">Features: </a> ${item.item._book.description || ''}<ul style='padding: 20px'>`;
					item.item.featuresList.forEach((promoStackItem) => {
						this.content += `<li>${promoStackItem}</li>`;
					});
					this.content += '</ul></div></div>';
					this.content += `<div style="display: flex;flex-direction: column;
					flex 1;margin: 60px;align-items: center;justify-content: center">`;
					this.content += `<img style="width: 100px; height: auto" src="${item.item._book.amazonImageUrl || DEFAULT_BOOK_COVER}" />`;
					this.content += '</div>';

					this.content += '</div>';
				}

				if (item.itemType === 'subscription') {
					this.content += `
						<div style="
							display:flex; 
							flex-direction: row; 
							border-bottom:1px dashed rgba(0,0,0,.6); 
							padding-bottom:20px; 
							margin-bottom: 20px;
						">
							<div style="flex-grow: 1">
								<div style="font-weight:bold;">Subscription</div><br/>
								<div><a style="color:#7E7E7E">Membership: </a>${item.item.membershipLevel}</div>
								<div><a style="color:#7E7E7E">Price: </a>$${item.item.paymentAmount}</div>
								<div>
									<a style="color:#7E7E7E">Renewal Date: </a>
									${moment(this.order.orderCompletedDate).add(1, 'year').format('MMMM Do, YYYY')}
								</div>
							</div>
							<div>
								<div style="
									margin: 10px 60px; 
									padding-top: 1px;
								" class="card-badge ${item.item.membershipLevel.toLowerCase()} ${item.item.membershipLevel.toLowerCase()}-background">
									<div class="wwm-icon"></div>
									${item.item.membershipLevel}
								</div>
							</div>
						</div>
					`;
				}
			});
		}
		this.content += '<p style="font-style:italic;font-size:14px">Written Word Media is a media company that helps authors ';
		this.content += 'promote books. It is the parent company for the following brands: Freebooksy, Bargain Booksy, ';
		this.content += 'Red Feather Romance, NewInBooks, Reader Reach, and Audio Thicket. '
        + 'Additional Information can be found at www.writtenwordmedia.com</p>';
	}

	ngOnDestroy() {
		this.author$.unsubscribe();
	}

	print() {
		this.showButtons = false;
		setTimeout(() => {
			window.print();
		}, 100);
	}

	getPurchasedDate(subscription) {
		return subscription.renewalCount >= 1 ? subscription.updated : subscription.created;
	}
}
