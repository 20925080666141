import { Component, Input } from '@angular/core';
import { OrderService } from '../../core/services/data/order.service';
import { ModalService } from '../../core/services/modal.service';

export const VARIANT_CART_ITEM = 'cartItem';
export const VARIANT_CHECKOUT_ITEM = 'checkoutItem';
const VARIANTS = [
	VARIANT_CART_ITEM,
	VARIANT_CHECKOUT_ITEM,
];
@Component({
	selector: 'app-cart-subscription-nugget',
	templateUrl: './cart-subscription-nugget.component.html',
	styleUrls: ['./cart-subscription-nugget.component.scss'],
})
export class CartSubscriptionNuggetComponent {
	public itemImage;

	public VARIANT_CART_ITEM = VARIANT_CART_ITEM;

	public VARIANT_CHECKOUT_ITEM = VARIANT_CHECKOUT_ITEM;

	public _variant = VARIANT_CART_ITEM;

	public detailsExpanded = false;

	public paymentAmount;

	public errorText;

	public subscription;

	public confirmText = 'Are you sure you want to remove subscription from your cart?';

	public buttonText = { confirm: 'Remove Subscription', cancel: 'Go Back' };

	public loading = false;

	@Input()
	set item(item) {
		if (item && item.item) {
			this.subscription = item.item;
		}
	}

	@Input()
	set variant(variant) {
		if (variant && VARIANTS.includes(variant)) {
			this._variant = variant;
		}
	}

	constructor(
		private os: OrderService,
		private ms: ModalService,
	) { }

	get _id() {
		return this.subscription?._id;
	}

	get modalId() {
		return `remove-confirm-modal-${this._id}`;
	}

	openConfirmationModal() {
		this.ms.open(this.modalId);
	}

	onItemRemove(event) {
		if (!event) return;

		this.loading = true;
		const item = { type: 'subscription', ...this.subscription };
		this.os.removeFromCart(item)
			.then(() => {
				this.loading = false;
			})
			.catch((error) => {
				this.loading = false;
				this.errorText = [`Uh oh, something went wrong. ${error}`];
				this.ms.open('cart-item-error-modal');
			});
		this.ms.close(this.modalId);
	}
}
