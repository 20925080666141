import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-tooltip-card',
	templateUrl: './tooltip-card.component.html',
	styleUrls: ['./tooltip-card.component.scss'],
})
export class TooltipCardComponent {
	@Input() label: string;

	@Input() text: string;

	public showTooltip = false;

	constructor() { }

	hideTooltip() {
		setTimeout(() => {
			this.showTooltip = false;
		}, 400);
	}
}
