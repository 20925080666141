import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { ReplaySubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ServerConnectionService } from '../server-connection.service';
import { Book } from '../../../classes/book';
import { ToastService } from '../toast.service';
import { CoreModule } from '../../core.module';

import { BookAPIResponse, BooksAPIResponse } from '../../../types/responses';
import { IBook } from '../../../types/datatypes';

declare const location: Location;

@Injectable({
	providedIn: CoreModule,
})
export class BooksService {
	private _booksStore$ = new ReplaySubject<Array<Book>>(1);

	private _booksById$ = new ReplaySubject(1);

	private dataStore = {
		store: [],
		byId: {},
	};

	private savedBook;

	constructor(
		private scs: ServerConnectionService,
		private ts: ToastService,
	) { }

	get booksStore$() {
		return this._booksStore$.asObservable();
	}

	get booksById$() {
		return this._booksById$.asObservable();
	}

	public parseAmazonWhereBookAvailable(otherOptionsTitles, otherOptionsLinks, book: IBook) {
		_.forEach(otherOptionsTitles, (option, i) => {
			if (option.match(/Paperback/)) {
				book.whereAvailable.paperback = otherOptionsLinks[i].match(/javascript/) ? book.amazonLink : otherOptionsLinks[i];
			} else if (option.match(/Hardcover/)) {
				book.whereAvailable.hardcover = otherOptionsLinks[i].match(/javascript/) ? book.amazonLink : otherOptionsLinks[i];
			} else if (option.match(/Audiobook/)) {
				book.whereAvailable.audiobook = otherOptionsLinks[i].match(/javascript/) ? book.amazonLink : otherOptionsLinks[i];
			}
		});
	}

	public scrape(url) {
		const ASIN = Book.parseAmazonId(url);
		return this.doScrape(ASIN);
	}

	public doScrape(ASIN) {
		let amazonFail = false;
		if (location.search && location.search.indexOf('amazonFail=1') !== -1) {
			amazonFail = true;
		}
		const body = {
			bookId: ASIN,
			doScrape: true,
			amazonFail,
		};

		return this.scs.http$('POST', '/api/books/amazonSearch', null, body);
	}

	public getBook(ASIN) {
		let amazonFail = false;
		if (location.search && location.search.indexOf('amazonFail=1') !== -1) {
			amazonFail = true;
		}

		return this.scs.http$('GET', `/api/books/checkBook/${ASIN}`, `amazonFail=${amazonFail}`, null);
	}

	public save(book) {
		if (book._id) {
			delete book.__v;
			return this.scs.http$('PUT', `/api/books/${book._id}`, null, book);
		}
		return this.scs.http$('POST', '/api/books/', null, book);
	}

	public fetchBooksByOwner(userId) {
		this.scs.http$('GET', `/api/books/byOwner/${userId}`, null, null).subscribe(
			(result: BooksAPIResponse) => {
				if (result.success) {
					result.books.forEach((book) => {
						this.addBook(book);
					});
					this.allDone();
				}
			},
		);
	}

	public fetchBookById(id) {
		return this.scs.http$('GET', `/api/books/${id}`, null, null).pipe(
			tap((result: BookAPIResponse) => {
				if (result.success) {
					this.addBook(result.book);
					this.allDone();
				}
			}),
		);
	}

	public getBooksByAuthor(authorName) {
		return this.scs.http$('GET', `/api/books/byAuthor/${encodeURIComponent(authorName)}`, null, null);
	}

	public addBooksBulk(author, books) {
		const data = {
			books,
			author: author._id,
		};
		return this.scs.http$('POST', '/api/books/addBulk', null, data);
	}

	public getSavedBook() {
		return this.savedBook;
	}

	public setSavedBook(obj) {
		this.savedBook = obj;
	}

	public clearData() {
		this.savedBook = null;
	}

	public updateBooks(books) {
		books.forEach((item) => {
			this.addBook(item);
		});
		this.allDone();
	}

	public fetchBooks(bookList) {
		let hasOne = false;
		const newList = [];
		bookList.forEach((item) => {
			if (!this.dataStore.byId[item]) {
				hasOne = true;
				newList.push(item);
			}
		});
		if (hasOne) {
			this.scs.http$('POST', '/api/books/idList', null, { list: bookList }).subscribe((result: BooksAPIResponse) => {
				if (result.success) {
					result.books.forEach((item) => {
						this.addBook(item);
					});
					this.allDone();
				}
			});
		}
	}

	public setBooks(books) {
		for (const book of books) {
			this.addBook(book);
		}
		this.allDone();
	}

	public addBook(book) {
		if (this.dataStore.byId[book._id]) {
			Object.keys(book).forEach((key) => {
				if (
					(key === 'count' || key === 'lastPub')
          && (book.count === null || book.lastPub === null)
				) {
					// No-Op - Do not overwrite previous results that calculated the book feature count
				} else {
					this.dataStore.byId[book._id][key] = book[key];
				}
			});
		} else {
			this.dataStore.byId[book._id] = book;
			this.dataStore.store.push(this.dataStore.byId[book._id]);
		}
	}

	public handleWebSocket(data) {
		// this.ts.addMessage(`The book <i>${data.data.title}</i> has been updated.`);
		this.addBook(data.data);
		this.allDone();
	}

	public uploadBookCover(formData) {
		return this.scs.http$('POST', '/api/books/cover-upload', null, formData);
	}

	public deleteGCSBookCover(storagePath) {
		return this.scs.http$('POST', '/api/books/cover-delete', null, { storagePath });
	}

	public bulkDelete(body) {
		return this.scs.http$('POST', '/api/books/bulkDelete', null, body);
	}

	private allDone() {
		this._booksStore$.next(this.dataStore.store);
		this._booksById$.next(this.dataStore.byId);
	}
}
