<section>
  <div *ngIf="showButtons && this.order" class="button-line">
    <button type="button" class="regular button solid-green" (click)="print()">Print</button>
  </div>
  <div class="content" *ngIf="this.order" [innerHtml]="content | keepHtml">
  </div>
  <div class="subscription content" *ngIf="subscription">
    <div class="header">
      <img class="logo" src="/assets/img/logos/wwm_primary.png">
      <button type="button" class="regular button solid-green" (click)="print()">Print</button>
    </div>

    <div class="receipt">Receipt</div>
    <hr>
    <div class="containerTop">
      <div>
        <p><b>Full name of purchaser:</b></p>
        <p>{{subscription.user.firstName}} {{subscription.user.lastName}}</p>
      </div>
      <div>
        <p><b>Membership Email:</b></p>
        <p class="email">{{subscription.user.username}}</p>
      </div>
      <div *ngIf="subscription.user.businessInfo && subscription.user.businessInfo.address && subscription.user.businessInfo.address.street1">
        <p><b>Business Info</b></p>
        <p>{{subscription.user.businessInfo.address.street1}} {{subscription.user.businessInfo.address.street2}}</p>
        <p>{{subscription.user.businessInfo.city}}, {{subscription.user.businessInfo.state}},
          {{subscription.user.businessInfo.postalCode}}</p>
        <p>{{subscription.user.businessInfo.country}}</p>
      </div>
    </div>
    <hr>
    <div class="containerMiddle">
      <div class="left">
        <p><b>Amound Paid:</b></p>
        <p><b>Product Type:</b></p>
        <p><b>Purchased Date:</b></p>
        <p><b>Payment Type:</b></p>
        <p><b>Transaction ID:</b></p>
      </div>
      <div class="right">
        <p>${{subscription.paidAmount}}</p>
        <p>Annual {{subscription.membershipLevel}} Membership <span *ngIf="subscription.renewalCount >= 1">Renewal</span></p>
        <p>{{moment(getPurchasedDate(subscription)).format('MMMM Do, YYYY')}}</p>
        <p>Credit Card</p>
        <p>{{orderId}}</p>
      </div>
    </div>
    <hr>
    <div class="containerFooter">
      <div>The charge will show up as WRITTEN WORD MEDIA or PAYPAL *WRTTN WORD.</div>
      <div>This receipt is a valid receipt of purchase. If you have any billing related questions please email
        <a href="mailto:info@writtenwordmedia.com">info&#64;writtenwordmedia.com</a>
      </div>
    </div>
    <hr>
    <div class="footer">
      Written Word Media is a media company that helps authors promote books. It is the parent company for the following
      brands: Freebooksy, Bargain Booksy, Red Feather Romance, NewInBooks, Reader Reach, and Audio Thicket. Additional
      Information can be found at www.writtenwordmedia.com
    </div>
  </div>
</section>
