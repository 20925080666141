import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { PromoCodeService } from '../../core/services/data/promo-code.service';
import { OrderService } from '../../core/services/data/order.service';
import { BooksService } from '../../core/services/data/books.service';
import { UserService } from '../../core/services/data/user.service';
import { ModalService } from '../../core/services/modal.service';
import { AnalyticsService } from '../../core/services/analytics.service';
import { PurchaseProcessService } from '../../core/services/data/purchase-process.service';

import { CartDiscountDetail } from '../../types/cart-discount-detail.interface';

const DEFAULT_BOOK_COVER = '/assets/img/defaults/default_book_image_400w_simple.jpg';
const PROMO_TYPE_PERCENT = 'percent';
const PROMO_TYPE_AMOUNT = 'amount';
const PROMO_TYPES = [PROMO_TYPE_PERCENT, PROMO_TYPE_AMOUNT];

export const VARIANT_CART_ITEM = 'cartItem';
export const VARIANT_CHECKOUT_ITEM = 'checkoutItem';
const VARIANTS = [
	VARIANT_CART_ITEM,
	VARIANT_CHECKOUT_ITEM,
];
@Component({
	selector: 'app-cart-feature-nugget',
	templateUrl: './cart-feature-nugget.component.html',
	styleUrls: ['./cart-feature-nugget.component.scss'],
})
export class CartFeatureNuggetComponent implements OnInit {
	public itemImage;

	public VARIANT_CART_ITEM = VARIANT_CART_ITEM;

	public VARIANT_CHECKOUT_ITEM = VARIANT_CHECKOUT_ITEM;

	public _variant = VARIANT_CART_ITEM;

	public detailsExpanded = false;

	public paymentAmount;

	public discountDisplay = '';

	public discount = 0;

	public discountText = 'Discount';

	public errorText;

	public feature;

	public pubDate;

	public bookPrice;

	public bookImage;

	public confirmText = 'Are you sure you want to remove this promotion from your cart?';

	public buttonText = { confirm: 'Remove Promotion', cancel: 'Go Back' };

	public loading = false;

	public discountDetails: CartDiscountDetail[] = [];

	public hasSpeculativeDiscount;

	public speculativeDiscountText;

	private author;

	private author$;

	@Input()
	set item(item) {
		if (item && item.item) {
			this.feature = item.item;
			this.hasSpeculativeDiscount = item.hasSpeculativeDiscount;
			this.speculativeDiscountText = item.speculativeDiscountText;
		}
	}

	@Input()
	set variant(variant) {
		if (variant && VARIANTS.includes(variant)) {
			this._variant = variant;
		}
	}

	get hasDiscount() {
		return (this.discount > 0) && this.feature?._siteAdvert?.price !== this.paymentAmount;
	}

	constructor(
		private router: Router,
		private os: OrderService,
		private bs: BooksService,
		private us: UserService,
		private ms: ModalService,
		private pcs: PromoCodeService,
		private analyticsService: AnalyticsService,
		private purchaseProcessService: PurchaseProcessService,
	) { }

	ngOnInit() {
		this.author$ = this.us.author$.subscribe(
			(data: any) => {
				this.author = data;
			},
		);
		this.paymentAmount = this.feature.paymentAmount;
		this.discount = this.feature.discountAmount;
		if (this.feature._promoCode) {
			const { code } = this.feature._promoCode;
			this.discountText = code.slice(0, 4) === 'MEM-' ? 'Member Discount' : 'Discount';
		}
		this.pubDate = moment(this.feature.pubDate, 'YYYYMMDD').format('MMMM Do, YYYY');
		this.bookPrice = this.feature.bookPriceDuringPromo ? `$${this.feature.bookPriceDuringPromo.toFixed(2)}` : 'Free';
		this.itemImage = this.feature._book.amazonImageUrl || DEFAULT_BOOK_COVER;
		this.generateDiscountDetails();
	}

	get _id() {
		return this.feature?._id;
	}

	get modalId() {
		return `remove-confirm-modal-${this._id}`;
	}

	generateDiscountDetails() {
		if (this.hasSpeculativeDiscount) {
			this.discountDetails.push({ label: 'Promo Subtotal Price', value: this.feature._siteAdvert.price });
			this.discountDetails.push({ label: 'Membership Discount', value: -this.discount });
			return;
		}

		if (this.hasValidPromoCode() === false) return;

		this.discountDetails.push({ label: 'Promo Subtotal Price', value: this.feature._siteAdvert.price });
		this.discountDetails.push({ label: this.discountText, value: -this.discount });
	}

	hasValidPromoCode() {
		const discountType = this.feature?._promoCode?.discountType;
		return discountType && PROMO_TYPES.includes(discountType);
	}

	openConfirmationModal() {
		this.ms.open(this.modalId);
	}

	onItemRemove(event) {
		if (!event) return;

		this.purchaseProcessService.clearEdit();

		this.loading = true;
		const featureData = { type: 'feature', ...this.feature };
		this.os.removeFromCart(featureData)
			.then(() => {
				if (this.feature._promoCode && this.feature._promoCode.discountLimit !== 0) {
					const currentLimit = { currentExpense: -this.feature.discountAmount };
					this.pcs.logUse(this.feature._promoCode._id, currentLimit).subscribe((result: any) => {
						this.loading = false;
					});
				}

				this.loading = false;
			})
			.catch((error) => {
				this.loading = false;
				this.errorText = [`Uh oh, something went wrong. ${error}`];
				this.ms.open('cart-item-error-modal');
			});
		this.ms.close(this.modalId);
	}

	toggleDetails() {
		this.detailsExpanded = !this.detailsExpanded;
	}

	editFeature() {
		const { _site, _siteAdvert, purchaserInfo } = this.feature;

		this.purchaseProcessService.setDetails({
			feature: {
				ASIN: this.feature._book.ASIN || this.feature.ASIN,
				whereAvailable: this.feature._book.whereAvailable,
				bookPriceDuringPromo: this.feature.bookPriceDuringPromo,
				purchaserInfo,
				_site: this.feature._site?._id || this.feature._site,
				_siteAdvert: this.feature._siteAdvert?._id || this.feature._siteAdvert,
			},
			book: {
				ASIN: this.feature._book.ASIN || this.feature.ASIN,
				whereAvailable: this.feature._book.whereAvailable,
				title: this.feature._book.title,
				description: this.feature._book.description,
				bookLength: this.feature._book.bookLength,
				nameOfSeries: this.feature._book.nameOfSeries,
				bookNumber: this.feature._book.bookNumber,
				numberOfBooksInSeries: this.feature._book.numberOfBooksInSeries,
				threesomesOrRomanticScenes: this.feature._book.threesomesOrRomanticScenes,
				endingOfBook: this.feature._book.endingOfBook,
				cheatingOrInfidelity: this.feature._book.cheatingOrInfidelity,
				commonThemes: this.feature._book.commonThemes,
				author: this.feature._book.author,
				amazonImageUrl: this.feature._book.amazonImageUrl,
				amazonLink: this.feature._book.amazonLink,
				link: this.feature._book.link,
			},
		}, 'cart-feature-edit-nugget');
		this.purchaseProcessService.edit(this.feature);
		const pubDateWindow = this.feature.pubDate;

		let featurePath = 'features';

		if (this.feature.purchaseSource === 'Route 2') {
			featurePath = 'features2';
		}

		let link = `/${featurePath}/${_site.seoSlug}/${_siteAdvert.seoSlug}/${pubDateWindow}`;
		if (this.author && this.author.authorLink) {
			link = `/author-dashboard/${this.author.authorLink}/${featurePath}/${_site.seoSlug}/${_siteAdvert.seoSlug}/${pubDateWindow}`;
		}
		this.os.setEditingItemId(this._id);
		this.os.setCartNotVisible();

		// For some reason router.navigate doesn't work if we're on the promo wizard page.
		// Upon navigating to home, it loses track of the link to go to, and instead refreshes and loads feature step 1.
		// This is a workaround to get to the proper link from the promo-wizard.

		if (this.author && this.router.url === `/author-dashboard/${this.author.authorLink}/promo-wizard`) {
			this.router.navigate([link], { queryParams: { feature: this.feature._id } }).then();
			return;
		}

		this.router.navigate(['/']).then(() => this.router.navigate([link], { queryParams: { feature: this.feature._id } }));
	}
}
