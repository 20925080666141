import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { PromoCodeService } from '../../core/services/data/promo-code.service';
import { OrderService } from '../../core/services/data/order.service';
import { BooksService } from '../../core/services/data/books.service';
import { UserService } from '../../core/services/data/user.service';
import { ModalService } from '../../core/services/modal.service';
import { AnalyticsService } from '../../core/services/analytics.service';
import { PromoStackPurchaseService } from '../../core/services/data/promo-stack-purchase.service';

import { CartDiscountDetail } from '../../types/cart-discount-detail.interface';

const DEFAULT_BOOK_COVER = '/assets/img/defaults/default_book_image_400w_simple.jpg';

const PROMO_TYPE_PERCENT = 'percent';
const PROMO_TYPE_AMOUNT = 'amount';
const PROMO_TYPES = [PROMO_TYPE_PERCENT, PROMO_TYPE_AMOUNT];
const AUTHOR_DASHBOARD = '/author-dashboard';

export const VARIANT_CART_ITEM = 'cartItem';
export const VARIANT_CHECKOUT_ITEM = 'checkoutItem';
const VARIANTS = [
	VARIANT_CART_ITEM,
	VARIANT_CHECKOUT_ITEM,
];
@Component({
	selector: 'app-cart-promo-stack-nugget',
	templateUrl: './cart-promo-stack-nugget.component.html',
	styleUrls: ['./cart-promo-stack-nugget.component.scss'],
})
export class CartPromoStackNuggetComponent implements OnInit {
	public itemImage;

	public VARIANT_CART_ITEM = VARIANT_CART_ITEM;

	public VARIANT_CHECKOUT_ITEM = VARIANT_CHECKOUT_ITEM;

	public _variant = VARIANT_CART_ITEM;

	public detailsExpanded = false;

	public paymentAmount;

	public discountDisplay = '';

	public discount = 0;

	public discountText = 'Discount';

	public duration;

	public audience;

	public errorText;

	public promoStack;

	public pubDate;

	public bookPrice;

	public bookImage;

	public confirmText = 'Are you sure you want to remove this promotion from your cart?';

	public buttonText = { confirm: 'Remove Promotion', cancel: 'Go Back' };

	public loading = false;

	public discountDetails: CartDiscountDetail[] = [];

	public hasSpeculativeDiscount;

	public speculativeDiscountText;

	private author;

	private author$;

	@Input()
	set item(item) {
		if (item && item.item) {
			this.promoStack = item.item;
			this.hasSpeculativeDiscount = item.hasSpeculativeDiscount;
			this.speculativeDiscountText = item.speculativeDiscountText;
		}
	}

	@Input()
	set variant(variant) {
		if (variant && VARIANTS.includes(variant)) {
			this._variant = variant;
		}
	}

	get hasDiscount() {
		return (this.discount > 0) && this.promoStack?._promoStack?.price !== this.paymentAmount;
	}

	constructor(
		private router: Router,
		private os: OrderService,
		private bs: BooksService,
		private us: UserService,
		private ms: ModalService,
		private pcs: PromoCodeService,
		private analyticsService: AnalyticsService,
		private promoStackPurchaseService: PromoStackPurchaseService,
	) { }

	ngOnInit() {
		this.author$ = this.us.author$.subscribe(
			(data: any) => {
				this.author = data;
			},
		);

		if (this.promoStack?._promoCode?.code?.includes('MEM-')) {
			this.discountText = 'Member Discount';
		}

		// TODO: Apply Discount
		this.audience = this.promoStack._promoStack.audience;
		this.duration = this.promoStack._promoStack.releaseDuration;
		this.pubDate = moment(this.promoStack.startDate, 'YYYYMMDD').format('MMMM Do, YYYY');
		this.paymentAmount = this.promoStack.paymentAmount;
		this.discount = this.promoStack._promoStack.discountedPrice - this.paymentAmount;
		this.itemImage = this.promoStack._book?.amazonImageUrl || DEFAULT_BOOK_COVER;
		this.bookPrice = this.promoStack?.bookPriceDuringPromo ? `$${this.promoStack?.bookPriceDuringPromo.toFixed(2)}` : 'Free';

		this.generateDiscountDetails();
	}

	get _id() {
		return this.promoStack?._id;
	}

	get modalId() {
		return `remove-confirm-modal-${this._id}`;
	}

	generateDiscountDetails() {
		if (this.hasSpeculativeDiscount) {
			this.discountDetails.push({ label: 'Promo Subtotal Price', value: this.promoStack._promoStack.discountedPrice });
			this.discountDetails.push({ label: 'Membership Discount', value: -this.discount });
			return;
		}

		if (this.hasValidPromoCode() === false) return;

		this.discountDetails.push({ label: 'Promo Price', value: this.promoStack._promoStack.discountedPrice });
		this.discountDetails.push({ label: this.discountText, value: -this.discount });
	}

	hasValidPromoCode() {
		const discountType = this.promoStack?._promoCode?.discountType;
		return discountType && PROMO_TYPES.includes(discountType);
	}

	openConfirmationModal() {
		this.ms.open(this.modalId);
	}

	onItemRemove(event) {
		if (!event) return;

		this.loading = true;
		const promoStackItem = { type: 'promoStack', ...this.promoStack };
		this.os.removeFromCart(promoStackItem)
			.then(() => {
				if (this.promoStack._promoCode && this.promoStack._promoCode.discountLimit !== 0) {
					const currentLimit = { currentExpense: -this.discount };
					this.pcs.logUse(this.promoStack._promoCode._id, currentLimit).subscribe((result: any) => {
						this.loading = false;
					});
				}

				this.loading = false;
			})
			.catch((error) => {
				this.loading = false;
				this.errorText = [`Uh oh, something went wrong. ${error}`];
				this.ms.open('cart-item-error-modal');
			});
		this.ms.close(this.modalId);
	}

	toggleDetails() {
		this.detailsExpanded = !this.detailsExpanded;
	}

	editStack() {
		this.promoStackPurchaseService.editPromoStack(this.promoStack);
		const link = this.promoStackPurchaseService.getLink();

		const authorPath = this.author ? `${AUTHOR_DASHBOARD}/${this.author.authorLink}` : '';

		this.os.setCartNotVisible();

		this.router.navigate([authorPath + link]);
	}
}
