<div *ngIf="userService.me">
    <div *ngIf="show" class="member-menu">
        <ul>
            <li>
                {{ userService.me.username}}
            </li>
        </ul>
        <ul>
            <li>
                <a (click)="show = !show" [routerLink]="'/author-dashboard/'+userService.me.authorLink+'/account'">My Account</a>
            </li>
            <li class="member-badge {{ role?.toLowerCase() === 'platinum'? 'no-cursor' : '' }}" (click)="upgradePage()">
                <div class="flex">
                    <div class="wwm-icon" [class]="role"></div>
                    <div>
                        <div class="role-name" [class]="role">{{roleName}} Member</div>
                        <div class="upgrade-tag" *ngIf="role?.toLowerCase() !== 'platinum'">Upgrade Membership</div>
                    </div>
                </div>
            </li>
            <li>
                <a (click)="show = !show" [routerLink]="'/author-dashboard/'+userService.me.authorLink+'/billing'">My Billing</a>
            </li>
        </ul>
        <ul>
            <li>
                <a (click)="goToSupport()">Help Center</a>
            </li>
            <li>
                <a (click)="contactSupport()">Contact Support</a>
            </li>
            <li>
                <a (click)="logout()">Logout</a>
            </li>
        </ul>
    </div>
    <app-member-badge 
        class="{{ show? 'menu-opened' : '' }} 
        {{ role === 'platinum'? 'platinum-role' : '' }}" 
        (click)="show = !show"></app-member-badge>
</div>
<app-contact-form></app-contact-form>
