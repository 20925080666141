import { Injectable } from '@angular/core';
import { ReplaySubject, map } from 'rxjs';
import { ServerConnectionService } from '../server-connection.service';
import { CoreModule } from '../../core.module';

@Injectable({
	providedIn: CoreModule,
})
export class SitesService {
	public slugs = {
		FREEBOOKSY_SLUG: 'free-booksy',
		RED_FEATHER_SLUG: 'red-feather-romance',
		BARGAIN_BOOKS_SLUG: 'bargain-booksy',
		NEW_IN_BOOKS_SLUG: 'new-in-books',
		AUDIO_THICKET_SLUG: 'audio-thicket',
		FACEBOOK_ADS_SLUG: 'facebook-ads',
	};

	private _sitesStore = <ReplaySubject<Array<any>>> new ReplaySubject(1);

	private _sitesBySlug = <ReplaySubject<Object>> new ReplaySubject(1);

	private _sitesById = <ReplaySubject<Object>> new ReplaySubject<Object>(1);

	private dataStore = {
		store: [],
		bySlug: {},
		byId: {},
	};

	private facebookOrAmazonAdSites = {
		facebookAds: '5f998d3f3b75580011de6600',
		amazonAds: '62702050366323ac5dfddb55',
	};

	constructor(private scs: ServerConnectionService) {
		this.fetchSites();
	}

	get sitesBySlug$() {
		return this._sitesBySlug.asObservable();
	}

	get sitesStore$() {
		return this._sitesStore.asObservable();
	}

	get sitesById$() {
		return this._sitesById.asObservable();
	}

	public getSiteBySlug(slug) {
		return this.sitesBySlug$.pipe(map((item) => item[slug]));
	}

	public fetchSites() {
		this.scs.http$('GET', '/api/sites', null, null).subscribe(
			(result: any) => {
				result.sites.forEach((item) => {
					if (item.sitePublic) {
						this.addSite(item);
					}
				});
				this.allDone();
			},
		);
	}

	public addSite(site) {
		if (this.dataStore.byId[site._id]) {
			Object.keys(site).forEach((key) => {
				this.dataStore.byId[site._id][key] = site[key];
			});
		} else {
			this.dataStore.byId[site._id] = site;
			this.dataStore.bySlug[site.seoSlug] = this.dataStore.byId[site._id];
			this.dataStore.store.push(this.dataStore.byId[site._id]);
		}
	}

	public isFacebookOrAmazonAdSite(siteName) {
		const siteId = this.dataStore.bySlug[siteName]?._id;

		if (!siteId) return false;

		const facebookOrAmazonAdSiteIdes = Object.values(this.facebookOrAmazonAdSites);

		return facebookOrAmazonAdSiteIdes.includes(siteId);
	}

	public allDone() {
		this._sitesById.next({ ...this.dataStore.byId });
		this._sitesBySlug.next({ ...this.dataStore.bySlug });
		this._sitesStore.next(this.dataStore.store.slice());
	}

	public handleWebSocket(data) {
		if (data.action === 'update' || data.action === 'create') {
			this.addSite(data.data);
		}
		if (data.action === 'delete') {
			this.removeSite(data.data._id);
		}
	}

	public removeSite(site) {
		delete this.dataStore.byId[site._id];
		this.dataStore.store.some(
			(item, index, array) => {
				if (item._id === site._id) {
					array.splice(index, 1);
					return true;
				}

				return false;
			},
		);
		this.allDone();
	}

	public getFriendlySiteNameBySlug(siteName) {
		const site = this.dataStore.bySlug[siteName];
		if (site) {
			return site.name;
		}
		return siteName;
	}

	public getSiteById(siteId) {
		return this.dataStore.byId[siteId];
	}
}
