import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { SharedModule } from '../shared/shared.module';
import { LoginV2Component } from './loginV2/login.component';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { SignupV2Component } from './signupV2/signup.component';
import { SignupComponent } from './signup/signup.component';
import { ConfirmAccountComponent } from './confirm-account/confirm-account.component';

@NgModule({
	imports: [
		RouterModule,
		SharedModule,
		RecaptchaModule,
		RecaptchaFormsModule,
	],
	declarations: [LoginV2Component, LoginComponent, ForgotPasswordComponent, SignupV2Component, SignupComponent, ConfirmAccountComponent],
})
export class LoginModule {
}
