import { Injectable } from '@angular/core';
import { CoreModule } from '../core.module';

import { SettingsService } from './data/settings.service';
import { UrlQueryService } from './url-query.service';

@Injectable({
	providedIn: CoreModule,
})
export class ExperimentsService {
	private chosenVariant: string;

	constructor(
		private settingsService: SettingsService,
		private urlQueryService: UrlQueryService,
	) {
		const { ecoverride } = this.urlQueryService.load();

		if (ecoverride) {
			this.chosenVariant = ecoverride;
			return;
		}

		this.settingsService.getExperimentSettings().subscribe(({ setting }) => {
			this._controller(setting.value);
		});
	}

	getVariant() {
		this.chooseVariant();
		return this.chosenVariant;
	}

	private chooseVariant() {
		if (this.chosenVariant) return;
		this.chosenVariant = 'control';
	}

	// Handles if an experiment should occur or not. This can implement any logic, as long as the output is a boolean.

	private _controller(value): void {
		const select = {
			force_control: 'control',
			force_experimental: 'experimental',
			fifty_fifty: this._fiftyFifty(),
			prefer_control: this._preferControl(),
			prefer_experimental: this._preferExperimental(),
		};

		this.chosenVariant = select[value];
	}

	private _fiftyFifty(): string {
		return (Math.ceil(Math.random() * 10) % 2) ? 'experimental' : 'control';
	}

	// 70/30 split between control and experimental

	private _preferControl(): string {
		return !(Math.ceil(Math.random() * 10) % 3) ? 'experimental' : 'control';
	}

	// 30/70 split between control and experimental

	private _preferExperimental(): string {
		return (Math.ceil(Math.random() * 10) % 3) ? 'experimental' : 'control';
	}
}
