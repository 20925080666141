import { Injectable } from '@angular/core';
import { CoreModule } from '../core.module';

import { EnvironmentService } from './environment.service';
import { UrlQueryService } from './url-query.service';

@Injectable({
	providedIn: CoreModule,
})
export class RecaptchaService {
	public key = null;

	private LOCAL_TEST_KEY = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';

	private PROD_KEY = '6LeFjp4UAAAAAIENT7mO2TWQJobzLsMdj0xCMs8F';

	constructor(
		private environmentService: EnvironmentService,
		private urlQueryService: UrlQueryService,
	) {
		const { rcoverride } = this.urlQueryService.load();

		if (rcoverride) {
			this.key = this.LOCAL_TEST_KEY;
			return;
		}

		this.key = this.environmentService.isLocal() ? this.LOCAL_TEST_KEY : this.PROD_KEY;
	}
}
