import { InputBase } from './input-base';

export class InputText extends InputBase<string> {
	controlType = 'text';

	type: string;

	constructor(options: any = {}) {
		super(options);
		this.type = options.type || 'text';
	}
}
