import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { ReplaySubject } from 'rxjs';
import { ServerConnectionService } from './server-connection.service';
import { CoreModule } from '../core.module';

@Injectable({
	providedIn: CoreModule,
})
export class AlertsService {
	public _alerts = new ReplaySubject(1);

	private features;

	private dataStore = {
		alerts: {
			late_link: [],
			feature_live: [],
			failed_feature: [],
			cart_abandon: [],
			survey_follow_up: [],
			prep_for_feature: [],
			keep_in_touch: [],
		},
	};

	private inProgress = false;

	constructor(
		private scs: ServerConnectionService,
	) { }

	getBooks(authorId) {
		if (this.inProgress) {
			return;
		}
		this.inProgress = true;
		this.scs.http$('GET', `/api/features/alerts/${authorId}`, null, null).subscribe((result: any) => {
			this.features = result.features;
			this.setAlerts();
		});
	}

	get alerts() {
		return this._alerts.asObservable();
	}

	setAlerts() {
		const today = parseInt(moment().format('YYYYMMDD'), 10);
		this.features.forEach((feature) => {
			// late link
			const pubMoment = moment(feature.pubDate, 'YYYYMMDD');
			const llstart = parseInt(pubMoment.subtract(5, 'd').format('YYYYMMDD'), 10);
			pubMoment.add(5, 'd');
			if (feature._book?.title === 'TBD' && today >= llstart && today < feature.pubDate) {
				this.dataStore.alerts.late_link.push(feature);
			}
			// feature live
			const flend = parseInt(pubMoment.add(2, 'd').format('YYYYMMDD'), 10);
			pubMoment.subtract(2, 'd');
			if (feature.klaviyoLive && today < flend) {
				this.dataStore.alerts.feature_live.push(feature);
			}
			// failed feature
			const ffend = parseInt(pubMoment.add(10, 'd').format('YYYYMMDD'), 10);
			pubMoment.subtract(10, 'd');
			if (feature.reviewStatus === 'failed-feature' && today < ffend) {
				this.dataStore.alerts.failed_feature.push(feature);
			}
			// survey follow up
			const sfstart = parseInt(pubMoment.add(5, 'd').format('YYYYMMDD'), 10);
			pubMoment.subtract(5, 'd');
			const sfend = parseInt(pubMoment.add(35, 'd').format('YYYYMMDD'), 10);
			pubMoment.subtract(35, 'd');
			if (feature.klaviyoLive && today >= sfstart && today <= sfend) {
				if (feature._site.name !== 'New In Books') {
					let link = 'https://www.research.net/r/';
					switch (feature._site.name) {
						case 'Freebooksy':
							link += 'freebooksy-customer-feedback';
							break;
						case 'Bargain Booksy':
							link += 'bargainbooksy';
							break;
						case 'Red Feather Romance':
							link += 'RFR-author-feedback';
							break;
						default:
							break;
					}
					feature.surveyLink = link;
					this.dataStore.alerts.survey_follow_up.push(feature);
				}
			}
			// prep for feature
			const pfstart = parseInt(pubMoment.subtract(5, 'd').format('YYYYMMDD'), 10);
			pubMoment.add(5, 'd');
			if (today >= pfstart && today <= feature.pubDate) {
				this.dataStore.alerts.prep_for_feature.push(feature);
			}
			// sixty day keep in touch
			if (this.dataStore.alerts.keep_in_touch.length > 0) {
				if (feature.pubDate > this.dataStore.alerts.keep_in_touch[0].pubDate) {
					this.dataStore.alerts.keep_in_touch[0] = feature;
				}
			} else {
				this.dataStore.alerts.keep_in_touch = [feature];
			}
		});
		if (this.dataStore.alerts.keep_in_touch[0]) {
			// calculate 60 day keep in touch off of si[0]
			const sistart = parseInt(moment(this.dataStore.alerts.keep_in_touch[0].pubDate, 'YYYYMMDD').add(60, 'd').format('YYYYMMDD'), 10);
			const siend = parseInt(moment(this.dataStore.alerts.keep_in_touch[0].pubDate, 'YYYYMMDD').add(75, 'd').format('YYYYMMDD'), 10);
			if (today <= sistart || today >= siend) {
				this.dataStore.alerts.keep_in_touch = [];
			}
		}
		this._alerts.next(this.dataStore.alerts);
		this.inProgress = false;
	}

	dismiss(alertType, author) {
		this.scs.http$('POST', `/api/users/dismiss/${author}/${alertType}`, null, null).subscribe(
			() => {
				this.dataStore.alerts[alertType] = [];
				this._alerts.next(this.dataStore.alerts);
			},
		);
	}
}
