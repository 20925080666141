import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GiveawayService } from '../../core/services/data/giveaway.service';
import { SiteAdvertsService } from '../../core/services/data/site-adverts.service';

@Component({
	selector: 'app-confirm-giveaway-email',
	templateUrl: './confirm-giveaway-email.component.html',
	styleUrl: './confirm-giveaway-email.component.scss',
})

export class ConfirmGiveawayEmailComponent implements OnInit, OnDestroy {
	public siteAdvertId;

	public siteAdvert;

	public confirmed = false;

	public errorText;

	public loading = false;

	public email;

	public featureId;

	private route$;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private sas: SiteAdvertsService,
		private gis: GiveawayService,
	) { }

	async ngOnInit() {
		this.route$ = this.route.params.subscribe(({ siteAdvertId, email, featureId }) => {
			this.siteAdvertId = siteAdvertId;
			this.email = email;

			if (email.includes(' ') && email.includes('writtenwordmedia.com')) {
				this.email = email.replace(' ', '+');
			}

			this.init();
			this.confirmEmail();
		});
	}

	ngOnDestroy() {
		this.route$.unsubscribe();
	}

	init() {
		this.siteAdvert = null;
		if (!this.siteAdvertId) return;

		this.sas.fetchSingleSiteAdvertById(this.siteAdvertId).subscribe((result: any) => {
			if (result.success) {
				this.siteAdvert = result.siteAdvert;
			}
		});
	}

	confirmEmail() {
		this.loading = true;

		const user = {
			email: this.email,
			siteAdvertId: this.siteAdvertId,
		};

		this.gis.confirmGiveawayEmail(user).subscribe((result: any) => {
			if (result.success) {
				this.confirmed = true;
			}

			this.loading = false;
		});
	}

	backToGiveaway() {
		if (!this.siteAdvert.giveaway?.landingPage) {
			window.location.href = this.siteAdvert.giveaway.sourceSite.domainUrl;
			return;
		}

		window.location.href = this.siteAdvert.giveaway.landingPage;
	}
}
