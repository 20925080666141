import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ServerConnectionService } from './server-connection.service';

import { CoreModule } from '../core.module';

@Injectable({
	providedIn: CoreModule,
})
export class UrlQueryService {
	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private scs: ServerConnectionService,
	) {
	}

	load() {
		const url = new URL(window.location.href);
		return Object.fromEntries(url.searchParams);
	}

	save(query) {
		this.router.navigate([], {
			relativeTo: this.activatedRoute,
			queryParams: query,
			queryParamsHandling: 'merge',
		});
	}

	unshortUrl(shortUrl) {
		const body = { shortUrl };

		return new Promise((resolve, reject) => {
			this.scs.http$('POST', '/api/url/unshort', null, body).subscribe((result: any) => {
				if (!result.success) return reject(new Error('Was an error trying to unshort url'));

				return resolve(result.url);
			});
		});
	}
}
