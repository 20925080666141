import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, map } from 'rxjs';
import { ServerConnectionService } from '../server-connection.service';
import { UtilitiesService } from '../utilities.service';
import { CoreModule } from '../../core.module';
import { ISiteAdvert } from '../../../types/datatypes';
import { SiteAdvertAPIResponse } from '../../../types/responses';

interface ISiteAdvertsStore {
	store: Array<ISiteAdvert>;
	bySlug: Record<string, ISiteAdvert>;
	byId: Record<string, ISiteAdvert>;
	byQuery: Array<ISiteAdvert>;
}

type DataStore = Record<string, ISiteAdvertsStore>;

@Injectable({
	providedIn: CoreModule,
})
export class SiteAdvertsService {
	private _siteAdvertsStore = <ReplaySubject<Array<ISiteAdvert>>> new ReplaySubject(1);

	private _siteAdvertsBySlug = <ReplaySubject<Record<string, ISiteAdvert>>> new ReplaySubject(1);

	private _siteAdvertsById = <ReplaySubject<Record<string, ISiteAdvert>>> new ReplaySubject<Object>(1);

	private dataStore: DataStore = {
		siteAdverts: {
			store: [],
			bySlug: {},
			byId: {},
			byQuery: [],
		},
	};

	constructor(
		private scs: ServerConnectionService,
		private us: UtilitiesService,
	) {
		this.fetchSiteAdverts(false);
	}

	get siteAdvertsBySlug$() {
		return this._siteAdvertsBySlug.asObservable();
	}

	get siteAdvertsStore$(): Observable<Array<ISiteAdvert>> {
		return this._siteAdvertsStore.asObservable();
	}

	get siteAdvertsById$(): Observable<Record<string, ISiteAdvert>> {
		return this._siteAdvertsById.asObservable();
	}

	public getSiteAdvertBySlug(slug): Observable<ISiteAdvert> {
		return this.siteAdvertsBySlug$.pipe(map((item) => item[slug]));
	}

	public fetchSiteAdverts(shouldFilter) {
		this.scs.http$('GET', '/api/site-adverts', `filter=${shouldFilter}`, null).subscribe(
			(result: any) => {
				this.finalize(result);
			},
		);
	}

	public fetchSingleSiteAdvertBySlug(slug) {
		this.scs.http$('GET', `/api/site-adverts/by-slug/${slug}`, null, null).subscribe(
			(result: SiteAdvertAPIResponse) => {
				if (result.success) {
					this.setAdvert(result.siteAdvert);
				}
			},
		);
	}

	public fetchSingleSiteAdvertById(id) {
		return this.scs.http$('GET', `/api/site-adverts/by-id/${id}`, null, null);
	}

	public setAdvert(siteAdvert) {
		this.finalize({ siteAdverts: [siteAdvert] });
	}

	public getSiteAdvertById(id): ISiteAdvert | null {
		return this.dataStore.siteAdverts.byId[id];
	}

	public getInventory(siteAdvert) {
		return this.scs.http$('GET', `api/site-adverts/future-dates/${siteAdvert}`, null, null);
	}

	private finalize(result) {
		result.siteAdverts.forEach(
			(item) => {
				this.dataStore.siteAdverts.bySlug[item.seoSlug] = item;
				this.dataStore.siteAdverts.byId[item._id] = item;
				this.dataStore.siteAdverts.store.push(item);
			},
		);
		this.dataStore.siteAdverts.store = this.us.uniqueArray(this.dataStore.siteAdverts.store, '_id');
		this._siteAdvertsBySlug.next({ ...this.dataStore.siteAdverts.bySlug });
		this._siteAdvertsStore.next(this.dataStore.siteAdverts.store.slice());
		this._siteAdvertsById.next({ ...this.dataStore.siteAdverts.byId });
	}
}
