import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-spinner',
	templateUrl: './spinner.component.html',
	styleUrl: './spinner.component.scss',
})

export class SpinnerComponent {
	@Input() zoom = 0.60;

	@Input() label = 'Loading';

	@Input() labelFontSize = 1;
}
