import { Injectable, Inject } from '@angular/core';
import * as Rollbar from 'rollbar';
import { ServerConnectionService } from './server-connection.service';
import { RollbarService } from '../../rollbar';

import { CoreModule } from '../core.module';
import { EnvironmentService } from './environment.service';

@Injectable({
	providedIn: CoreModule,
})
export class LogsService {
	constructor(
		private environmentService: EnvironmentService,
		private scs: ServerConnectionService,
		@Inject(RollbarService) private rollbarService: Rollbar,
	) { }

	/**
   * Accepts a log object containing a type and message field
   * @param log: { type: '', message: '' }
   */
	public postLog(log) {
		this.scs.http$('POST', '/server/log', null, log).subscribe();
	}

	public rollbarInfo(log: string): void {
		if (this.environmentService.isNotProduction()) {
			// eslint-disable-next-line no-console
			console.info(log);
			return;
		}

		this.rollbarService.info(log);
	}
}
