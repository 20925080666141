import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalService } from '../../core/services/modal.service';

@Component({
	selector: 'app-confirm-modal',
	templateUrl: './confirm-modal.component.html',
	styleUrls: ['../error-modal/error-modal.component.scss', './confirm-modal.component.scss'],
})
export class ConfirmModalComponent {
	@Input() text;

	@Input() modalId;

	@Input() maskClose;

	@Input()
	set buttonText(buttonText) {
		if (buttonText) {
			this._buttonText = buttonText;
			if (buttonText.cancel) {
				this.title = 'ARE YOU SURE';
				this.success = false;
			} else {
				this.title = 'Success!';
				this.success = true;
			}
		}
	}

	@Output() confirmChoice = new EventEmitter();

	public title = 'Are You Sure?';

	public _buttonText;

	public success;

	constructor(
		private ms: ModalService,
	) { }

	close() {
		this.confirmChoice.emit(true);
		this.ms.close(this.modalId);
	}

	cancel() {
		this.confirmChoice.emit(false);
		this.ms.close(this.modalId);
	}
}
