<app-modal [id]="modalId" [maskClose]="false">
  <aside class="modal success-modal">
    <div class="modal-body">
      <div class="header">
        <p class="text">Success!</p>
        <div class="button regular close" (click)="close()"><span class="icon">&#x00D7;</span></div>
      </div>
      <div class="content">
        {{text}}
      </div>
      <div class="footer">
        <div class="button regular solid-blue" (click)="close()">OK</div>
      </div>
    </div>
  </aside>
  <div class="confirm-modal-background"></div>
</app-modal>
