import { ReplaySubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CoreModule } from '../../core.module';

import { PromoStack, PromoStackPopulated } from '../../../types/promo-stacks';
import { ErrorResponse } from '../../../types/error-response';
import { ServerConnectionService } from '../server-connection.service';
import { OrderPromoStackResult } from '../../../types/promo-stacks/order-promo-stack-result.interface';

@Injectable({
	providedIn: CoreModule,
})

export class PromoStackService {
	public statuses: ['in-progress', 'public', 'archived'];

	private _promoStackStore = <ReplaySubject<Object>> new ReplaySubject(1);

	private dataStore = {
		store: [],
	};

	constructor(
		private serverConnectionService: ServerConnectionService,
	) { }

	get promoStackStore$() {
		return this._promoStackStore.asObservable();
	}

	public getPromoStacks() {
		this.serverConnectionService.http$('GET', '/api/promostacks/list-available', null, null).subscribe(
			(promoStacks: PromoStackPopulated[]) => {
				this.dataStore.store = promoStacks;
				this._promoStackStore.next(this.dataStore.store);
			},
		);
	}

	public getPromoStackById(id: string): PromoStackPopulated {
		return this.dataStore.store.find((promoStack: PromoStack) => promoStack._id === id);
	}

	public getPromoStackByNameKabob(nameKabob: string): PromoStackPopulated {
		return this.dataStore.store.find((promoStack: PromoStack) => this._kabobCase(promoStack.name) === nameKabob);
	}

	public getPromoStack(id: string): Observable<Object | PromoStack | ErrorResponse> {
		return this.serverConnectionService.http$('GET', `/api/promostacks/${id}`, null, null);
	}

	public getPromoStackAvailability(id: string) {
		return this.serverConnectionService.http$('GET', `/api/promostacks/availability/${id}`, null, null);
	}

	public createPromoStack(promoStackId: string, orderId: string): Promise<PromoStack> {
		return new Promise((resolve, reject) => this.serverConnectionService.http$(
			'POST', '/api/order-promo-stacks/create', null, { promoStackId, orderId },
		).subscribe((data: OrderPromoStackResult) => resolve(data.orderPromoStacks), reject));
	}

	public updatePromoStack(
		id: String,
		features: any[],
		bookId: string,
		startDate: string,
		dates: any[],
		promoCode: any,
		paymentAmount: number,
		bookPriceDuringPromo: number,
	) {
		const data = { id, features, bookId, startDate, promoCode, dates, paymentAmount, bookPriceDuringPromo };
		return new Promise((resolve, reject) => this.serverConnectionService.http$('POST', '/api/order-promo-stacks/update', null, data)
			.subscribe((result: OrderPromoStackResult) => resolve(result.orderPromoStacks), reject));
	}

	public updatePromoStackFeatures(updates) {
		return new Promise((resolve, reject) => this.serverConnectionService
			.http$('POST', '/api/order-promo-stacks/update-order-features', null, updates)
			.subscribe((result: OrderPromoStackResult) => resolve(result.orderPromoStacks), reject));
	}

	public getOrderPromoStacksByIds(ids): Observable<Object | ErrorResponse> {
		const body = {
			ids,
		};

		return this.serverConnectionService.http$('POST', '/api/order-promo-stacks/list-by-ids', null, body);
	}

	private _kabobCase(str: string) {
		return str.toLowerCase().replace(/ /g, '-');
	}
}
