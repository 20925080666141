import { InputText } from '../../classes/input-text';

export class SignupForm {
	email = new InputText({
		key: 'email',
		label: 'Email',
		required: true,
		pattern: 'email',
	});

	password = new InputText({
		key: 'password',
		label: 'Password',
		minLength: 7,
		required: true,
		type: 'password',
	});

	firstName = new InputText({
		key: 'firstName',
		label: 'First Name',
		required: true,
	});

	lastName = new InputText({
		key: 'lastName',
		label: 'Last Name',
		required: true,
	});
}
