import { InputBase } from '../../classes/input-base';
import { InputText } from '../../classes/input-text';

export class ContactForm {
	email = new InputText({
		key: 'email',
		label: 'Email',
		required: true,
	});

	name = new InputText({
		key: 'name',
		label: 'Name',
		required: true,
	});

	message = new InputBase({
		key: 'message',
		label: 'Message',
		controlType: 'textarea',
	});
}
