import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { FeaturedBooksService } from '../../core/services/data/featured-books.service';
import { UserService } from '../../core/services/data/user.service';

@Component({
	selector: 'app-receipt',
	templateUrl: './receipt.component.html',
	styleUrls: ['./receipt.component.scss'],
})
export class ReceiptComponent implements OnInit, OnDestroy {
	public author;

	public content;

	public showButtons = true;

	private promo;

	private author$;

	private features$;

	private featureId;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private fbs: FeaturedBooksService,
		private us: UserService,
	) { }

	ngOnInit() {
		this.featureId = this.route.snapshot.params.feature;
		this.author$ = this.us.author$.subscribe((data: any) => {
			this.author = data;
		});
		this.fbs.fetchFullFeaturedBookById(this.featureId).subscribe((data: any) => {
			this.promo = data.featuredBook;
			let paymentType = 'None';
			let paymentId = '';
			if (this.promo.paymentAmount > 0) {
				if (this.promo.paymentInfo?.stripeToken) {
					paymentType = 'Credit Card';
					paymentId = this.promo.paymentInfo.stripeToken.id;
				} else if (this.promo.paymentInfo?.payPalToken) {
					paymentType = 'PayPal';
					paymentId = this.promo.paymentInfo.payPalToken.paymentID;
				} else {
					// May have to see why this is necessary...
					paymentType = 'Other';
				}
			} else if (this.promo._promoCode || (this.promo.oldImportPromoCode && this.promo.oldImportPromoCode.length > 0)) {
				paymentType = 'Coupon';
			}
			this.content = '<div style="border-bottom:1px dashed rgba(0,0,0,.6);margin-bottom:20px"><img style="margin-left:-28px;width:400px;"';
			this.content += 'src="https://secure.writtenwordmedia.com/assets/img/logos/wwm_primary.png" /></div>';
			if (!this.promo._site || !this.promo._siteAdvert) {
				this.content += '<div style="padding-bottom:20px;border-bottom:1px dashed rgba(0,0,0,.6);margin-bottom:20px">Receipt unavailable, ';
				this.content += 'please contact support at <a style="color: #008af4" ';
				this.content += 'href="mailto:support@writtenwordmedia.com">support@writtenwordmedia.com</a></div>';
			} else {
				this.content += '<div style="font-weight:400;font-size:28px;margin-bottom: 20px;">Receipt of Payment</div>';
				this.content += `<p>Thank you ${this.promo.purchaserInfo.firstName}, for your purchase of a <strong>`;
				this.content += `${this.promo._site.name} ${this.promo._siteAdvert.name} Promotion!</strong></p>`;
				this.content += `<p>We have your book, <strong>${this.promo._book.title}</strong> by ${this.promo._book.author}, scheduled `;
				this.content += `as a promotion on <strong>${moment(this.promo.pubDate, 'YYYYMMDD').format('MMMM Do, YYYY')}</strong>. `;
				this.content += 'Please contact us at <a style="text-decoration:none;color:#008af4" href="mailto:support@writtenwordmedia.com">';
				this.content += 'support@writtenwordmedia.com</a> if you have any questions related to billing or promotions.</p>';
				this.content += '<p style="padding-bottom:20px;border-bottom:1px dashed rgba(0,0,0,.6);margin-bottom:20px">';
				this.content += 'The charge will show up as WRITTEN WORD MEDIA or PAYPAL *WRTTN WORD.</p>';
				this.content += '<div style="padding-bottom:20px;border-bottom:1px dashed rgba(0,0,0,0.6);margin-bottom:20px">';
				this.content += '<strong style="font-size: 18px">Receipt</strong><br/>';
				this.content += `<strong>Full Name of Purchaser:</strong> ${this.promo.purchaserInfo.firstName} `;
				this.content += `${this.promo.purchaserInfo.lastName}<br/>`;
				this.content += `<strong>Date Paid:</strong> ${moment(this.promo.datePurchased).format('MMMM Do, YYYY')}<br/>`;
				this.content += `<strong>Item Purchased:</strong> ${this.promo._site.name} ${this.promo._siteAdvert.name}<br/>`;
				this.content += `<strong>Book Promoted:</strong> ${this.promo._book.title}<br/>`;
				this.content += `<strong>Book Run Date:</strong> ${moment(this.promo.pubDate, 'YYYYMMDD').format('MMMM Do, YYYY')}<br/>`;
				this.content += `<strong>Amount Paid:</strong> $${this.promo.paymentAmount}<br/>`;
				this.content += `<strong>Payment Type:</strong> ${paymentType}<br/>`;
				if (paymentId) {
					this.content += `<strong>Transaction ID:</strong> ${paymentId}<br/>`;
				}
				if (this.promo.bookPriceDuringPromo) {
					this.content += `<strong>Book Price:</strong> $${this.promo.bookPriceDuringPromo}<br/>`;
				}
				if (this.promo.description) {
					this.content += `<strong>Book Description:</strong> ${this.promo.description}<br/>`;
				}
				this.content += `<strong>Amazon Link:</strong> ${this.promo._book.amazonLink}</div>`;
			}
			this.content += '<p style="font-style:italic;font-size:14px">Written Word Media is a media company that helps authors ';
			this.content += 'promote books. It is the parent company for the following brands: Freebooksy, Bargain Booksy, ';
			this.content += 'Red Feather Romance, NewInBooks, Reader Reach, and Audio Thicket.';
			this.content += ' Additional Information can be found at www.writtenwordmedia.com</p>';
		});
	}

	ngOnDestroy() {
		this.author$.unsubscribe();
	}

	print() {
		this.showButtons = false;
		setTimeout(() => {
			window.print();
		}, 100);
	}

	email() {

	}
}
