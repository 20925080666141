import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalService } from '../../core/services/modal.service';

@Component({
	selector: 'app-error-modal',
	templateUrl: './error-modal.component.html',
	styleUrls: ['./error-modal.component.scss'],
})

export class ErrorModalComponent {
	@Input() text;

	@Input() soldOut;

	@Input() title;

	@Input() amazonError;

	@Input() modalId;

	@Input() maskClose;

	@Input()
	set soldOutExtra(soldOutExtra) {
		if (soldOutExtra && Object.keys(soldOutExtra).length) {
			this._soldOutExtra = [];
			this._soldOutExtraIds = [];
			Object.keys(soldOutExtra).forEach((id) => {
				this._soldOutExtraIds.push(id);
				this._soldOutExtra.push(soldOutExtra[id]);
			});
		}
	}

	@Input()
	set extraButtons(extraButtons) {
		if (extraButtons) {
			this._extraButtons = extraButtons;
			this._extraButtonType = typeof extraButtons;
			if (extraButtons === 'login') {
				this.closeText = 'Try Signup Again';
			}
		}
	}

	@Output() soldOutEvent = new EventEmitter();

	public closeText = 'TRY AGAIN';

	public _extraButtons;

	public _soldOutExtra;

	public _soldOutExtraIds;

	public _extraButtonType;

	constructor(
		private ms: ModalService,
	) { }

	close(id) {
		this.ms.close(id);
	}

	closeAndGo(id) {
		this.ms.close(id);
		this.soldOutEvent.emit(this._soldOutExtraIds[0]);
	}
}
