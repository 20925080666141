import { Injectable } from '@angular/core';
import { CoreModule } from '../../core.module';

@Injectable({
	providedIn: CoreModule,
})
export class LocalStorageService {
	constructor() { }

	public saveData(key: string, value: string): void {
		localStorage.setItem(key, value);
	}

	public saveJSONData(key: string, value: JSON | Object): void {
		const json = JSON.stringify(value);
		this.saveData(key, json);
	}

	public getData(key: string): string {
		return localStorage.getItem(key);
	}

	public getJSONData(key: string): any {
		return JSON.parse(this.getData(key));
	}

	public removeData(key: string): void {
		localStorage.removeItem(key);
	}

	public clearData(): void {
		localStorage.clear();
	}
}
