import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalService } from '../../core/services/modal.service';

@Component({
	selector: 'app-success-modal',
	templateUrl: './success-modal.component.html',
	styleUrls: ['../error-modal/error-modal.component.scss', './success-modal.component.scss'],
})
export class SuccessModalComponent {
	@Input() text;

	@Input() modalId;

	@Input() maskClose;

	@Output() confirmChoice = new EventEmitter();

	constructor(
		private ms: ModalService,
	) { }

	close() {
		this.confirmChoice.emit(true);
		this.ms.close(this.modalId);
	}
}
