import { Injectable } from '@angular/core';
import { ReplaySubject, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import * as moment from 'moment';
import { ServerConnectionService } from '../server-connection.service';
import { FeaturedBook } from '../../../classes/featured-book';
import { ToastService } from '../toast.service';
import { BooksService } from './books.service';
import { CoreModule } from '../../core.module';

@Injectable({
	providedIn: CoreModule,
})
export class FeaturedBooksService {
	private _featuredBooksStore = <ReplaySubject<Array<FeaturedBook>>> new ReplaySubject(1);

	private _featuredBooksById = <ReplaySubject<Object>> new ReplaySubject(1);

	private dataStore = {
		store: [],
		byId: {},
	};

	private books;

	constructor(
		private scs: ServerConnectionService,
		private ts: ToastService,
		private bs: BooksService,
	) {
		this.bs.booksById$.subscribe((data: any) => {
			this.books = data;
		});
	}

	get featuredBooksById$() {
		return this._featuredBooksById.asObservable();
	}

	get featuredBooksStore$() {
		return this._featuredBooksStore.asObservable();
	}

	public addBook(featuredBook, shouldFinalize = false) {
		if (this.dataStore.byId[featuredBook._id]) {
			Object.keys(featuredBook).forEach((key) => {
				this.dataStore.byId[featuredBook._id][key] = featuredBook[key];
			});
		} else {
			this.dataStore.byId[featuredBook._id] = featuredBook;
			this.dataStore.store.push(this.dataStore.byId[featuredBook._id]);
		}

		if (shouldFinalize) {
			this.finalizeFeaturedBooks();
		}
	}

	public createFeaturedBook(featuredBook) {
		featuredBook.created = moment().toISOString();
		featuredBook.datePurchased = moment().toISOString();
		return this.scs.http$('POST', '/api/features', null, featuredBook).pipe(switchMap((result: any) => {
			if (result.success) {
				return this.fetchFullFeaturedBookById(result.featuredBook._id);
			}
			return of(null);
		}));
	}

	public completeOrder(orderObj) {
		return this.scs.http$('POST', '/api/features/complete-order', null, orderObj);
	}

	public fetchFullFeaturedBookById(id) {
		return this.scs.http$('GET', `/api/features/full/${id}`, null, null);
	}

	public fetchFeaturedBookListByRef(ref) {
		this.scs.http$('GET', '/api/features/by-_owner-list', `_owner=${ref}`, null).subscribe(
			(result: any) => {
				if (result.success) {
					result.featuredBooks.forEach((item) => {
						this.addBook(item);
					});
					this.finalizeFeaturedBooks();
				}
			},
		);
	}

	public fetchFeaturesByOwner(owner) {
		return this.scs.http$('GET', `/api/features/ownerList/${owner}`, null, null);
	}

	public freePurchase(featuredBook) {
		return this.scs.http$('POST', '/api/users/create-free-payment', null, featuredBook);
	}

	public sendStripePayment(featuredBook) {
		return this.scs.http$('POST', '/api/users/create-stripe-payment', null, featuredBook);
	}

	public getFeaturesBySiteAdvert(siteAdvertId) {
		const body = {
			siteAdvertId,
		};

		return this.scs.http$('POST', '/api/site-advert/get-features', null, body);
	}

	public sendMail(data, text) {
		const body = {
			text,
			data,
		};
		return this.scs.http$('POST', '/api/features/sendMail', null, body);
	}

	public getApprovedButNotLive(id) {
		return this.scs.http$('GET', `/api/features/editedButNotLive/${id}`, null, null);
	}

	public getFeatureBlackoutDates(ASIN, gracePeriod = 30) {
		const body = {
			ASIN,
			gracePeriod,
		};
		return this.scs.http$('POST', '/api/features/blackout-dates', null, body);
	}

	public saveNotes(feature) {
		const body = {
			authorNotes: feature.authorNotes,
		};
		return this.scs.http$('PUT', `/api/features/saveNotes/${feature._id}`, null, body).subscribe();
	}

	public handleWebSocket(data) {
		// Only attempt to handle websocket events with data in them. Some are sent with only IDs

		const book = data?.data?._book;

		if (!book) return;

		const _book = this.books[book];

		if (_book) {
			this.ts.addMessage(`The feature <i>${_book.title}</i> running on `
        + `${moment(data.data.pubDate, 'YYYYMMDD').format('MM/DD/YYYY')} has been updated`);
			this.addBook(data.data);
			this.finalizeFeaturedBooks();
		}
	}

	public updateFeaturedBook(feature) {
		const body = {
			_id: feature._id,
			_book: feature._book,
			pubDate: feature.pubDate,
			endPubDate: feature.endPubDate,
			purchaserInfo: feature.purchaserInfo,
			bookPriceDuringPromo: feature.bookPriceDuringPromo,
			description: feature.description,
			whereAvailable: feature.whereAvailable,
			permafree: feature.permafree,
			kindleUnlimited: feature.kindleUnlimited,
			freeEndDate: feature.freeEndDate,
			freeStartDate: feature.freeStartDate,
			targetGenres: feature._siteAdvert.targetGenres,
			_targetGenres: feature._siteAdvert._targetGenres,
			seriesLinks: feature.seriesLinks,
			subscribeToList: feature.subscribeToList,
			awardsAccolades: feature.awardsAccolades,
			kindleCountdownDeal: feature.kindleCountdownDeal,
			_site: feature._site._id,
			_siteAdvert: feature._siteAdvert._id,
			dealOfDay: feature.dealOfDay || false,
		};

		return this.scs.http$('PUT', `/api/features/update-by-user/${feature._id}`, null, body)
			.pipe(switchMap((result: any) => {
				if (result.success) {
					return this.fetchFullFeaturedBookById(result.featuredBook._id);
				}
				return of(null);
			}));
	}

	public featuresForPromotions(query) {
		return this.scs.http$('GET', '/api/features/promotions', query, null);
	}

	public featureBrief(query) {
		return this.scs.http$('GET', '/api/features/feature-brief', query, null);
	}

	public statsBrief(query) {
		return this.scs.http$('GET', '/api/features/stats-brief', query, null);
	}

	private finalizeFeaturedBooks() {
		this._featuredBooksById.next({ ...this.dataStore.byId });
		this._featuredBooksStore.next(this.dataStore.store.slice());
	}
}
