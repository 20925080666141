import { Injectable } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { ServerConnectionService } from '../server-connection.service';
import { UtilitiesService } from '../utilities.service';
import { CoreModule } from '../../core.module';

@Injectable({
	providedIn: CoreModule,
})
export class PromoCodeService {
	private _promoCodeById = new ReplaySubject(1);

	private _promoCodeByCode = new ReplaySubject(1);

	private _promoCodeStore = new ReplaySubject<Array<any>>(1);

	private _updatePromoCode = new Subject<boolean>();

	private dataStore = {
		promoCode: {
			byId: {},
			byCode: {},
			store: [],
		},
	};

	constructor(
		private scs: ServerConnectionService,
		private us: UtilitiesService,
	) {
		this._promoCodeById.next({});
	}

	get promoCodeById$() {
		return this._promoCodeById.asObservable();
	}

	get promoCodeByCode$() {
		return this._promoCodeByCode.asObservable();
	}

	get promoCodeStore() {
		return this._promoCodeStore.asObservable();
	}

	get updatePromoCode() {
		return this._updatePromoCode.asObservable();
	}

	triggerUpdatePromoCode() {
		this._updatePromoCode.next(true);
	}

	public checkCode(promoCode, siteAdvert, email, referrer, user) {
		let query;
		if (user) {
			query = `user=${user}`;
		}
		return this.scs.http$('GET', `/api/promo-codes/${promoCode}/${siteAdvert}/${email}/${referrer}`, query, null);
	}

	public getMembershipPromocode(username) {
		return this.scs.http$('GET', `/api/subscriptions/promocode/${username}`, null, null);
	}

	public fetchCodes() {
		this.scs.http$('GET', '/api/promo-codes/', null, null).subscribe(
			(result: any) => {
				this.finalizePromoCodes(result);
			},
		);
	}

	public finalizePromoCodes(result) {
		result.promoCodes.forEach(
			(code) => {
				this.dataStore.promoCode.byId[code._id] = code;
				this.dataStore.promoCode.byCode[code.code] = code;
				this.dataStore.promoCode.store.push(code);
			},
		);
		this.dataStore.promoCode.store = this.us.uniqueArray(this.dataStore.promoCode.store, '_id');
		this._promoCodeById.next(this.dataStore.promoCode.byId);
		this._promoCodeByCode.next(this.dataStore.promoCode.byCode);
		this._promoCodeStore.next(this.dataStore.promoCode.store);
	}

	public createCode(promoCode) {
		return this.scs.http$('POST', '/api/promo-codes', null, promoCode);
	}

	public addCode(promoCode) {
		this.dataStore.promoCode.byCode[promoCode.code] = promoCode;
		this.dataStore.promoCode.byId[promoCode._id] = promoCode;
		let noPromo = true;
		this.dataStore.promoCode.store.some(
			(item, index, array) => {
				if (item._id === promoCode._id) {
					noPromo = false;
					array.splice(index, 1, promoCode);
					return true;
				}

				return false;
			},
		);
		if (noPromo) {
			this.dataStore.promoCode.store.push(promoCode);
		}
		this.finalizePromoCodes({ promoCodes: [] });
	}

	public saveCode(promoCode) {
		return this.scs.http$('PUT', `/api/promo-codes/${promoCode._id}`, null, promoCode);
	}

	public removeCode(promoCode) {
		delete this.dataStore.promoCode.byCode[promoCode.code];
		delete this.dataStore.promoCode.byId[promoCode._id];
		this.dataStore.promoCode.store.some(
			(item, index, array) => {
				if (item._id === promoCode._id) {
					array.splice(index, 1);
					return true;
				}

				return false;
			},
		);
		this.finalizePromoCodes({ promoCodes: [] });
	}

	public deleteCode(codeId) {
		return this.scs.http$('DELETE', `/api/promo-codes/${codeId}`, null, null);
	}

	public fetchCodeByReferrer(referrer) {
		return this.scs.http$('GET', `/api/promo-codes/by-referrer/${referrer}`, null, null);
	}

	public fetchOwnerPromos(owner) {
		this.scs.http$('GET', `/api/promo-codes/by-_owner/${owner}`, null, null).subscribe((result: any) => {
			if (result.success) {
				this.finalizePromoCodes(result);
			}
		});
	}

	public logUse(promoCodeId, promoCodeCurrentLimit) {
		return this.scs.http$('PUT', `/api/promo-codes/log-use/${promoCodeId}`, null, promoCodeCurrentLimit);
	}
}
