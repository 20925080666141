import * as Rollbar from 'rollbar'; // When using Typescript < 3.6.0.
// `import Rollbar from 'rollbar';` is the required syntax for Typescript 3.6.x.
// However, it will only work when setting either `allowSyntheticDefaultImports`
// or `esModuleInterop` in your Typescript options.

import {
	Injectable,
	Inject,
	InjectionToken,
	ErrorHandler,
} from '@angular/core';

function determineEnvironment() {
	if (window.location.hostname === 'secure.writtenwordmedia.com') {
		return 'production';
	}

	return window.location.hostname.includes('localhost') ? 'local' : 'development';
}

const rollbarConfig = {
	accessToken: '00625117370f41168e80cd13d53db2f1',
	captureUncaught: true,
	captureUnhandledRejections: true,
	environment: determineEnvironment(),
};

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
	constructor(@Inject(RollbarService) private rollbar: Rollbar) {}

	handleError(err: any): void {
		if (determineEnvironment() === 'local') {
			// eslint-disable-next-line no-console
			console.error(err);
		} else {
			this.rollbar.error(err.originalError || err);
		}
	}
}

export function rollbarFactory() {
	return new Rollbar(rollbarConfig);
}
