import { Injectable } from '@angular/core';
import { ServerConnectionService } from './server-connection.service';
import { CoreModule } from '../core.module';

@Injectable({
	providedIn: CoreModule,
})
export class FrontService {
	constructor(
		private scs: ServerConnectionService,
	) { }

	sendContactForm(body) {
		return new Promise((resolve, reject) => {
			this.scs.http$('POST', '/api/front/contact-message', null, body).subscribe((result: any) => {
				if (!result.success) {
					reject(result.message);
				} else {
					resolve(result.generatedDescription);
				}
			});
		});
	}

	sendChangeRequest(body) {
		return new Promise((resolve, reject) => {
			this.scs.http$('POST', '/api/front/change-request', null, body).subscribe((result: any) => {
				if (!result.success) {
					reject(result.message);
				} else {
					resolve('');
				}
			});
		});
	}
}
