<app-spinner *ngIf="loading"></app-spinner>
<section *ngIf="!loading">
  <div class="header">
    <h1>Membership Plans</h1>
    <p>Compare the different levels of WWM Membership</p>
    <img src="../../../assets/img/rabbit.png" alt="">
  </div>
  <div class="content">
    <div *ngFor="let plan of plans"  class="{{plan.internalName}} row">
      <span class="{{plan.internalName}}-plan" *ngIf="plan.textSecondary">{{plan.textSecondary}}</span>
      <span class="headerPlan">
        <p>{{plan.name}}</p>
        <p class="price" *ngIf="discountEnabled(plan.roleAccess)" [ngClass]="{'discounted': plan.priceDiscounted !== ''}">
          ${{plan.price}}<span>/yr</span>
        </p>
        <p class="price" *ngIf="!discountEnabled(plan.roleAccess)">${{plan.price}}<span>/yr</span></p>
        <p class="price" *ngIf="plan.priceDiscounted && discountEnabled(plan.roleAccess)">${{plan.priceDiscounted}}<span>/yr</span></p>
        <p class="textDiscounted" *ngIf="plan.priceDiscounted && discountEnabled(plan.roleAccess)">
          Get your first year for only ${{plan.priceDiscounted}}
        </p>
      </span>
      <p class="current" *ngIf="plan.current">Current Plan</p>
      <button 
        class="regular button solid-green" 
        *ngIf="!plan.current && (['Gold', 'Platinum'].includes(plan.name) && existingRole !== 'Platinum')" 
        (click)="openModal(plan)"
      >
        Select Plan
      </button>
      <p class="off">{{plan.off}}</p>
      <span class="text">{{plan.text}}</span>

     <ul>
        <li [ngClass]="{'invalid': !plan.centralized}">Centralized Book List with promo reminders</li>
        <li [ngClass]="{'invalid': !plan.saved}">Saved Promo History and Schedule</li>
        <li [ngClass]="{'invalid': !plan.email}">Email Support</li>
        <li [ngClass]="{'invalid': !plan.author}">Author Community on Facebook</li>
        <li [ngClass]="{'invalid': !plan.discount}">10% discount on all promos</li>
        <li [ngClass]="{'invalid': !plan.early}">Early Access to inventory (14 days)</li>
        <li [ngClass]="{'invalid': !plan.partner}">Partner Discounts</li>
        <li [ngClass]="{'invalid': !plan.access}">Access to exclusive dedicated eblast product (Limelight)</li>
        <li [ngClass]="{'invalid': !plan.chat}">Live Chat Support</li>
        <li [ngClass]="{'invalid': !plan.betaProducts}">Priority Access to Beta Products</li>
        <li [ngClass]="{'invalid': !plan.editorial}">Priority Editorial Placement</li>
     </ul>

     <hr>
     <p class="change" *ngIf="plan.current && isFreeMember()">Upgrade to Gold or Platinum today to get better perks</p>
     <p class="current" *ngIf="plan.current && !isFreeMember()">Current Plan</p>
     <button 
        class="regular button solid-green" 
        *ngIf="!plan.current && (['Gold', 'Platinum'].includes(plan.name) && existingRole !== 'Platinum')" 
        (click)="openModal(plan)"
      >
        Select 
      </button>
    </div>
  </div>
</section>
