import { InputText } from '../classes/input-text';

export class UserForm {
	name = new InputText({
		key: 'name',
		label: 'Name',
		type: 'text',
		required: true,
		placeholder: 'Name',
	});

	email = new InputText({
		key: 'email',
		label: 'Email',
		type: 'email',
		required: true,
		placeholder: 'Email',
	});
}
