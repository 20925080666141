import { InputText } from '../../classes/input-text';

export class ForgotPasswordForm {
	email = new InputText({
		key: 'email',
		label: 'Email Address',
		required: true,
		pattern: 'email',
	});
}
