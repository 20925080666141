import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { switchMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ModalService } from '../../core/services/modal.service';
import { UtilitiesService } from '../../core/services/utilities.service';
import { UserService } from '../../core/services/data/user.service';
import { AlertsService } from '../../core/services/alerts.service';
import { SettingsService } from '../../core/services/data/settings.service';
import { OrderService } from '../../core/services/data/order.service';
import { AnalyticsService } from '../../core/services/analytics.service';

const DEFAULT_HOME_LINK = '/features';

@Component({
	// eslint-disable-next-line @angular-eslint/no-host-metadata-property
	host: {
		'(document:click)': 'onClick($event)',
	},
	selector: 'app-top-bar',
	templateUrl: './top-bar.component.html',
	styleUrls: ['./top-bar.component.scss'],
})
export class TopBarComponent implements OnInit, OnDestroy {
	public authorSide = true;

	public development;

	public showOps;

	public title: String;

	public user;

	public author;

	public alerts;

	public alertCount = 0;

	public noAlerts = true;

	public notificationsVisible = false;

	public cartPurchaseEnabled = true;

	public orderItemCount = 0;

	public homeRedirectLink = DEFAULT_HOME_LINK;

	public alerts$;

	public author$;

	public order$;

	public cartVisibility$;

	public settings$;

	public cartVisible = false;

	public refresh = true;

	public show = false;

	public cart;

	@Input() authorDetached = false;

	@Input() authorSet;

	constructor(
		private router: Router,
		private ms: ModalService,
		public uss: UserService,
		private us: UtilitiesService,
		private as: AlertsService,
		private ss: SettingsService,
		private os: OrderService,
		private analyticsService: AnalyticsService,
	) {}

	get isEmulated() {
		return this.uss.isEmulated;
	}

	ngOnInit() {
		this.os.fetchExistingOrder();
		this.ss.fetchCartPurchaseSetting().then(
			(value) => {
				this.cartPurchaseEnabled = !!value;
			},
		);

		this.ss.getLoginSettings();

		this.order$ = this.os.orderStore$.subscribe((data: any) => {
			this.cart = data;
			this.orderItemCount = (data && data.items ? data.items.length : 0);
		});
		this.cartVisibility$ = this.os.cartVisibleStatus$.subscribe((isCartOpen) => {
			this.cartVisible = !!isCartOpen;
		});
		if (this.authorSet) {
			this.author = this.authorSet;
		}

		this.uss.user$.subscribe((user: any) => {
			this.user = user;
			this.showOps = this.uss.isAdminOrSuperAdmin();
			this.os.fetchExistingOrder(user._id);
			if (this.user && this.user.authorLink) {
				this.homeRedirectLink = `/author-dashboard/${this.user.authorLink}/home`;
			}
		});

		// this.uss.fetchUser().then(
		// 	(user: any) => {
		// 		this.user = user;
		// 		this.showOps = this.uss.isAdminOrSuperAdmin();
		// 		this.os.fetchExistingOrder(user._id);
		// 		if (this.user && this.user.authorLink) {
		// 			this.homeRedirectLink = `/author-dashboard/${this.user.authorLink}/home`;
		// 		}
		// 	}, () => {
		// 		// No user was retrieved so check for an existing cookie and fetch existing order
		// 		this.os.fetchExistingOrder();
		// 		this.user = null;
		// 	},
		// );
		// this.author$ = this.uss.author$.subscribe(
		// 	(data: any) => {
		// 		this.author = data;
		// 		this.as.getBooks(this.author._id);
		// 		this.alerts$ = this.as.alerts.subscribe((data2) => {
		// 			this.alerts = data2;
		// 			this.alertCount = 0;
		// 			Object.keys(this.alerts).forEach((key) => {
		// 				if (this.alerts[key].length > 0 && this.author.alerts[key]) {
		// 					this.alertCount += 1;
		// 				}
		// 			});
		// 			if (this.alertCount > 0) {
		// 				this.noAlerts = false;
		// 			}
		// 		});
		// 	},
		// );
		this.author$ = this.uss.author$.pipe(
			tap((author: any) => {
			  this.author = author;
			  this.as.getBooks(author._id);
			}),
			switchMap(() => this.as.alerts),
		  ).subscribe((alerts) => {
			this.alerts = alerts;
			this.alertCount = Object.values(alerts)
			  .filter((alert) => alert.length > 0 && this.author.alerts[alert.type])
			  .length;
			this.noAlerts = this.alertCount === 0;
		  });
	}

	ngOnDestroy() {
		this.order$.unsubscribe();
		this.cartVisibility$.unsubscribe();
		this.author$.unsubscribe();
	}

	logout() {
		this.analyticsService.trackLogout();

		this.uss.logout().subscribe(
			() => {
				this.os.clearOrderCookie();
				this.uss.updateUser({});
				window.location.href = '/features';
			},
		);
	}

	onClick(event) {
		if (!event.target.classList.contains('menuDrop')) {
			this.show = false;
		}
	}

	goToForgotPassword() {
		this.router.navigate(['/forgot-password']).then();
	}

	goToSupport() {
		(window as any).open('https://support.writtenwordmedia.com/category/hvmn7oxmjs-written-word-media', '_blank');
	}

	toggleNotifications() {
		this.notificationsVisible = !this.notificationsVisible;
	}

	login() {
		this.us.saveRoute(window.location.pathname);

		this.router.navigate(['/login']);
	}

	signUp() {
		this.us.saveRoute(window.location.pathname);
		this.router.navigate(['/signup']);
	}

	handleCartClick() {
		this.handleCartAnalytics();
		return this.cartVisible ? this.os.setCartNotVisible() : this.os.setCartVisible();
	}

	handleCartAnalytics() {
		if (this.cartVisible || !this.cart?.items || !this.cart.items.length) {
			return;
		}

		const analyticsCartItems = [];
		let analyticsCartValue = 0;

		for (const item of this.cart.items) {
			if (item?.item?._site && item.item?._siteAdvert) {
				analyticsCartValue += item.item.paymentAmount;

				const { _siteAdvert, _site, paymentAmount } = item.item;

				analyticsCartItems.push(this.analyticsService.generateAnalyticsEcommerceObject(
					_siteAdvert.seoSlug,
					_siteAdvert.name,
					_site.name,
					_siteAdvert.parentGenre,
					paymentAmount,
					1,
				));
			}
		}

		this.analyticsService.trackViewCart(analyticsCartValue, analyticsCartItems);
	}

	isGoldMember() {
		return this.uss.isGoldMember();
	}

	isPlatinumMember() {
		return this.uss.isPlatinumMember();
	}

	roleName() {
		const role = this.author?.memberRole?.roleName;
		if (role) {
			return role;
		}

		return '';
	}

	roleNameUppercase() {
		const role = this.roleName();
		return role?.toUpperCase();
	}
}
