<div class="spinner-container">
    <div class="book-spinner" [style]="{ 'zoom': zoom }">
        <div class="inner">
          <div class="left"></div>
          <div class="middle"></div>
          <div class="right"></div>
        </div>
    
        <ul>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
    </div>
    <div class="spinner-text" [style]="{ 'font-size': labelFontSize + 'rem' }">{{ label }}...</div>
</div>
