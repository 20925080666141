import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

import { CoreModule } from '../../core.module';
import { ServerConnectionService } from '../server-connection.service';

import { IPopulatedGenres } from '../../../types/populated';
import { GenresAPIResponse } from '../../../types/responses';

@Injectable({
	providedIn: CoreModule,
})
export class GenreService {
	private _genreStore = <ReplaySubject<IPopulatedGenres[]>> new ReplaySubject(1);

	private _loaded = false;

	constructor(
		private serverConnectionService?: ServerConnectionService,
	) {

	}

	get genreStore$(): Observable<IPopulatedGenres[]> {
		return this._genreStore.asObservable();
	}

	public getGenres(): Observable<IPopulatedGenres[]> {
		// If we already have the genres, return the store
		if (this._loaded) {
			return this.genreStore$;
		}

		this.serverConnectionService.http$('GET', '/api/genres/', null, null)
			.subscribe((result: GenresAPIResponse) => {
				if (result.success) {
					this.loadGenres(result.genres);
				}
			});

		return this.genreStore$;
	}

	loadGenres(genres: IPopulatedGenres[]) {
		this._loaded = true;

		this._genreStore.next(genres);
	}

	getGenreNames(genres: IPopulatedGenres[]) {
		return genres.map(({ name }) => name);
	}

	getGenreNameById(genres: IPopulatedGenres[], id: string) {
		const genre = this.getGenreById(genres, id);

		if (!genre) {
			throw new Error(`Unable to find genre with id: ${id}`);
		}

		return genre.name;
	}

	_flattenGenres(genres: IPopulatedGenres[]) {
		return genres.map((genre) => [genre, ...genre._children]).flat();
	}

	getGenreById(genres: IPopulatedGenres[], id: string) {
		const _genres = this._flattenGenres(genres);
		return _genres.find(({ _id }) => _id === id);
	}

	getGenreByName(genres: IPopulatedGenres[], name: string) {
		const _genres = this._flattenGenres(genres);
		return _genres.find(({ name: genreName }) => genreName === name);
	}

	getGenreNamesByIds(genres: IPopulatedGenres[], ids: string[]) {
		if (!ids) return [];
		return ids.map((id) => this.getGenreById(genres, id).name);
	}

	getGenreIdsByNames(genres: IPopulatedGenres[], names: string[]) {
		if (!names) return [];

		return names.map((name) => this.getGenreByName(genres, name)._id);
	}

	getGenreIdByName(genres: IPopulatedGenres[], name: string) {
		const genre = this.getGenreByName(genres, name);
		return genre?._id;
	}
}
