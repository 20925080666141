<div class="discount-meter" (click)="toggle()" *ngIf="display">
    <div class="fill" [style]="mainFill"></div>
    <div class="value">${{ roundedAvailable | number }}</div>
</div>
<div class="card details" [hidden]="!show">
    <div class="flex mb-20">
        <h4>Written Word Media Credits</h4>
        <div class="available-credit">
            <div class="value">{{ available | currency:'USD' }}</div>
            <div class="label">Available Credit</div>
        </div>
    </div>
    <div class="flex legend">
        <div class="flex used">
            <div class="key"></div>
            Used Credit
        </div>
        <div class="flex available">
            <div class="key"></div>
            Available Credit
        </div>
    </div>
    <div class="bar" #meter>
        <div class="meter" >
            <div class="clear">
                <div class="fill" [style]="subFill">
                    <div class="value">{{ used | currency:'USD' }}</div>
                </div>
            </div>
        </div>
    </div>
    <div class="flex">
        <div *ngFor="let label of meterLabels">${{ label | number }}</div>
    </div>
</div>
