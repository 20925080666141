<div class="member-badge" [ngClass]="role" [style]="customStyle">
    <div class="texture"></div>
    <div class="member-details">
        <div class="wwm-icon"></div>
        <div class="member-name" *ngIf="userService.me && showUsername">{{ userService.me.firstName }} {{ userService.me.lastName }}</div>
        <div class="member-name" *ngIf="!showUsername">{{ userService.me?.memberRole?.roleName }} Membership</div>
        <div class="member-avatar" *ngIf="showAvatar">
            <img [src]="userService.me.imageUrl ?? defaultProfileImage " alt="gravatar">
        </div>
    </div>
</div>
