<div class="wrapper" [ngClass]="{'checkout-item': _variant === VARIANT_CHECKOUT_ITEM, 'cart-item': _variant === VARIANT_CART_ITEM}">
    <app-spinner *ngIf="loading"></app-spinner>
    <div class="container" *ngIf="!loading">
      <div class="item-contents flex">
        <div>
          <div class="gold gold-membership gold-background" *ngIf="subscription.membershipLevel === 'Gold'">
            <div class="wwm-icon"></div>
          </div>
          <div class="platinum platinum-membership platinum-background" *ngIf="subscription.membershipLevel === 'Platinum'">
            <div class="wwm-icon"></div>
          </div>
        </div>
        <div class="item-details">
          <div class="row">
            <div>
              <p class="title">{{subscription.membershipLevel}} Membership</p>
              <p>Recurs each year at $125/yr, good for up to $200 of discounts</p>
            </div>
            <p class="total-amount ml-10">${{subscription.paymentAmount?.toFixed(2)}}</p>
          </div>
        </div>
      </div>
      <div class="button regular close" (click)="openConfirmationModal()"><span class="icon">&#x00D7;</span></div>
    </div>
  </div>
  <app-confirm-modal
    [modalId]="modalId"
    [text]="confirmText"
    [buttonText]="buttonText"
    (confirmChoice)="onItemRemove($event)"
  ></app-confirm-modal>
<app-error-modal [modalId]="'cart-item-error-modal'" [text]="errorText"></app-error-modal>
