import { InputText } from '../../classes/input-text';

export class ResetPasswordForm {
	password1 = new InputText({
		key: 'password1',
		label: 'New Password',
		minLength: 7,
		required: true,
		type: 'password',
	});

	password2 = new InputText({
		key: 'password2',
		label: 'Confirm New Password',
		minLength: 7,
		required: true,
		type: 'password',
	});
}
