import { NgModule, Optional, SkipSelf } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
	imports: [HttpClientModule, BrowserAnimationsModule],
	exports: [BrowserAnimationsModule],
	declarations: [],
	providers: [],
})
export class CoreModule {
	constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
		if (parentModule) {
			throw new Error('CoreModule is already loaded, Import it in the AppModule only');
		}
	}
}
