import { Injectable } from '@angular/core';
import { ServerConnectionService } from '../server-connection.service';
import { CoreModule } from '../../core.module';

@Injectable({
	providedIn: CoreModule,
})
export class MemberRoleService {
	constructor(
		private scs: ServerConnectionService,
	) {}

	public fetchMemberRoles() {
		return this.scs.http$('GET', '/api/memberRoles', null, null);
	}
}
