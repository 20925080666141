import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';

import { UserService } from '../../core/services/data/user.service';
import { PromoCodeService } from '../../core/services/data/promo-code.service';

import { IPopulatedUser } from '../../types/populated';
import { PromocodeAPIResponse } from '../../types/responses';

@Component({
	selector: 'app-discount-meter',
	templateUrl: './discount-meter.component.html',
	styleUrls: ['./discount-meter.component.scss'],
})
export class DiscountMeterComponent implements OnInit, OnDestroy {
	@ViewChild('meter') meter;

	public used = 0;

	public available = 0;

	public total = 500;

	public mainFill = '';

	public subFill = '';

	public display = false;

	public show = false;

	public meterLabels = [];

	public author;

	private author$;

	constructor(
		private userService: UserService,
		private promoCodeService: PromoCodeService,
	) { }

	get roundedAvailable() {
		return Math.ceil(this.available);
	}

	ngOnInit() {
		this.author$ = this.userService.author$.subscribe((author: IPopulatedUser) => {
			this.display = this.userService.isPlatinumMember() || this.userService.isGoldMember();
			this.author = author;
			this._getAuthorPromoCode();
		});

		this.promoCodeService.updatePromoCode.subscribe(() => {
			this._getAuthorPromoCode();
		});
	}

	ngOnDestroy() {
		this.author$.unsubscribe();
	}

	_getAuthorPromoCode() {
		if (!this.author) {
			return null;
		}

		return this.promoCodeService.getMembershipPromocode(this.author.username)
			.subscribe(
				(result: PromocodeAPIResponse) => {
					if (result.success) {
						this.used = result.promoCode.currentLimit;
						this.total = result.promoCode.discountLimit;
						this.renderDiscount();
					}
				},
			);
	}

	renderDiscount() {
		const percentage = `${(this.used / this.total) * 205}px`;
		const meterWidth = this.meter.nativeElement.offsetWidth;
		this.available = this.total - this.used;
		this.mainFill = `width: ${percentage}`;
		this.subFill = `width: ${(this.used / this.total) * meterWidth}px`;

		this.createLabels();
	}

	createLabels() {
		this.meterLabels = [0, this.total * 0.25, this.total * 0.5, this.total * 0.75, this.total];
	}

	toggle() {
		this.show = !this.show;
		if (!this.show) return;
		const meterWidth = 277;
		this.subFill = `width: ${(this.used / this.total) * meterWidth}px`;
	}
}
