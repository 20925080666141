import { WhereAvailable } from '../types/where-available.interface';

export class Book {
	_id: string;

	created: any = null;

	updated: any = null;

	amazonBookDescription = '';

	amazonLink = '';

	amazonImageUrl = '';

	imageStoragePath = '';

	ASIN = '';

	author = '';

	narrator = '';

	similarAuthors = '';

	_bookGenres: string[] = [];

	bookLength: number = null;

	bookNumber: number = null;

	numReviews: number = null;

	reviewRating: number = null;

	boxedSet = false;

	commonThemes: string = null;

	count: number = null;

	lastPub: number = null;

	cheatingOrInfidelity = false;

	description: string = null;

	endingOfBook = 'none-of-these';

	isSeries = false;

	lastScraped: number = null;

	nameOfSeries: string = null;

	numberOfBooksInSeries: number = null;

	threesomesOrRomanticScenes = false;

	settingLocation = '';

	genderProtagonist = '';

	_primaryGenre = '';

	title = '';

	whereAvailable: WhereAvailable = {
		apple: '',
		kobo: '',
		sony: '',
		nook: '',
		googleplay: '',
		hardcover: '',
		paperback: '',
		audiobook: '',
		authorstore: '',
		smashwords: '',

		// rs
		audible: '',
		koboaudio: '',
		scribd: '',
		audiobookscom: '',
		// atk
		appleAudio: '',
		nookAudio: '',
		googleplayAudio: '',
	};

	purchaseSource?: string = null;

	public static parseAmazonId(urlParam) {
		let url = urlParam;
		if (!url || url.indexOf('http') === -1) return null;
		if (url.indexOf('/gp/product/') > -1) {
			url = url.replace('/gp/product/', '/dp/');
		}
		const splits = url.split('/');
		let amazonId = splits[splits.indexOf('dp') + 1];
		if (amazonId.indexOf('?') !== -1) {
			amazonId = amazonId.substr(0, amazonId.indexOf('?'));
		}
		if (amazonId.indexOf('http') > -1) {
			return null;
		}
		return amazonId;
	}

	public static generateAmazonUrlFromId(amazonId) {
		return `https://www.amazon.com/dp/${amazonId}`;
	}

	public static parseAmazonBookDescription(description) {
		let bookDescription;
		if (description) {
			bookDescription = description.substring(0,
				description.indexOf('Read more') !== -1 ? description.indexOf('Read more') : description.length).trim();
			bookDescription = bookDescription.substring(bookDescription.lastIndexOf('}') + 1).trim();
		}
		return bookDescription;
	}

	public static parseAmazonKindlePrice(price, otherOptionsTitles) {
		// eslint-disable-next-line no-useless-escape
		let kindlePrice = price ? price.match(/\$((?:\d|\,)*\.?\d+)/g) : [];
		if (kindlePrice[0]) {
			return parseFloat(kindlePrice[0].replace(/^\D+/g, ''));
		} if (otherOptionsTitles && otherOptionsTitles[0]) {
			// eslint-disable-next-line no-useless-escape
			kindlePrice = otherOptionsTitles[0].match(/\$((?:\d|\,)*\.?\d+)/g);
			return parseFloat(kindlePrice[0].replace(/^\D+/g, ''));
		}
		return null;
	}
}
